import Constants from '../constants.js'

import AuthUtil from '../authUtil'
import FirestoreUtil from '../firestoreUtil'

import { collection, query, where, documentId } from "firebase/firestore";

import axios from 'axios'

export default {
  async getPreferences () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const preferencesURL = `${Constants.API_BASE_PATH}/user/preferences`
    const response = await axios.get(preferencesURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response) {
      const currentUser = AuthUtil.auth.currentUser
      const metaData = {
        displayName: currentUser.displayName,
        email: currentUser.email,
        photoUrl: currentUser.photoUrl
      }
      return {metaData: metaData, preferences: response.data}
    } else {
      return null
    }
  },
  publicUsersDataRef (userIds) {
    return query(collection(FirestoreUtil.db, 'users'), where(documentId(), 'in', userIds))
  },
  async registerAlphaUser (contactEmail, storeURL, selectedServices) {
    const postRegisterAlphaURL = `${Constants.API_BASE_PATH}/users/register/ecommerce/alpha`

    const appCheckToken = await AuthUtil.getAppCheckToken()

    const body = {
      email: contactEmail,
      storeURL: storeURL
    }

    if (selectedServices && selectedServices.length > 0) {
      const serviceInterestMap = {}
      selectedServices.forEach(element => {
        serviceInterestMap[element] = true
      })
      body.services = serviceInterestMap
    }

    // console.log(`Calling post register user ${JSON.stringify(body)}`)
    const registerResponse = await axios.post(postRegisterAlphaURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (registerResponse && registerResponse.data && registerResponse.data.status) {
      return registerResponse.data.status
    } else {
      return null
    }
  },
  async getUsersPublicDeatails (uid) {
    const currentUser = AuthUtil.auth.currentUser
    const token = currentUser && (await currentUser.getIdToken())
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const userURL = `${Constants.API_BASE_PATH}/users/${uid}/public`
    const response = await axios.get(userURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response) {
      return response.data
    } else {
      return null
    }
  },
  async recordUsageStats (uid, eventId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postUsageStatsEndpoint = `${Constants.API_BASE_PATH}/user/${uid}/stats`

    const body = {
      source: 'developer.pikle.online',
      eventId: eventId
    }

    await axios.post(postUsageStatsEndpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  }
}
