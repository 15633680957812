<template>
  <v-app id="pikle">
    <v-main class="login-container">
      <v-container
        fluid
        class="mt-6"
      >
        <v-row
          align="center"
          justify="center"
          class="pt-6"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
            class="pt-6 d-flex justify-center"
          >
              <v-card class="mt-6 login-card" :elevation="6">
                <v-card-title class="d-flex align-center justify-center">
                    Coming soon
                </v-card-title>
                <v-card-text>
                  <v-container style="text-align: center; margin-top: 10px; padding-top: 30px; border-top: 1px solid #CCC;">
                    <!-- <h2>Coming soon</h2> -->
                    <p class="mt-6">Pikle ecommerce is currently in closed alpha and available on an invite bases only.</p>
                  </v-container>
                </v-card-text>

              </v-card>

          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'PikleEcommerceSignup',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-container {
  background: #0e2887;  
  background-image: linear-gradient(165deg, rgba(218, 24, 209, 0.3) 30%, rgba(11, 1, 17, 1) 70%), url('~@/assets/homepage/galaxy.webp');

  background-size: cover;
  background-position: bottom center;

  color: white;
  max-height: 400px;
  overflow-y: visible;
}
.login-card {
  max-width: 600px;
  height: 400px;
}
.comparison-list {
  display: flex;
  justify-content: space-around;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
  font-size: 1rem;
}
.developer-tag-line {
  margin-left: 46px;
}
</style>
