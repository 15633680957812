<template>
  <div :class="{'absolute': absolute}">
    <!-- <v-menu offset-y :open-on-hover="!$vuetify.breakpoint.mobile"> -->
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-avatar
          class="app-bar-avatar"
          :color="!photoUrl ? 'primary' : 'white'"
          v-bind="attrs"
          v-on="on"
          :size="32"
        >
          <v-img v-if="photoUrl" src="photoUrl"></v-img>
          <span v-else class="white--text text-subtitle-2">{{ userInitials }}</span>
        </v-list-item-avatar>
      </template>

      <v-card rounded="0" color="primary">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{displayName || userName || email}}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-title class="d-flex justify-center pa-2">
              <v-btn small :href="`${pikleOnlineDomain}/pikles`" target="_blank" text>
                <span class="mr-2">Pikle.online</span>
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="d-flex justify-center pa-2">
              <v-btn small href="#" @click.prevent="logout" text>
                <span class="mr-2">Sign Out</span>
                <v-icon small>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <!-- <v-list-item-avatar :color="!photoUrl ? 'primary' : 'white'">
      <v-img v-if="photoUrl" src="photoUrl"></v-img>
      <span v-else class="white--text text-subtitle-2">{{ userInitials }}</span>
    </v-list-item-avatar> -->

  </div>
</template>

<script>

import AuthUtil from '../../authUtil'

import { signOut } from "firebase/auth";

import { mapState } from 'vuex'

import Constants from '../../constants.js'

// Mixins
import resetStore from '@/mixins/ResetStore.js'

export default {
  name: 'UserMenu',
  mixins: [resetStore],
  data () {
    return {
    }
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      hideIntroPreference: state => state.user.hideIntro,
      prefsLoaded: state => state.user.loaded,
      showExperimentalFeatures: state => state.user.showExperimentalFeatures,
      userMetaData: state => state.user.metaData,
      userName: state => state.user.userName,
    }),
    displayName () {
      return this.userMetaData ? this.userMetaData.displayName : null
    },
    email () {
      return this.userMetaData ? this.userMetaData.email : null
    },
    photoUrl () {
      return this.userMetaData ? this.userMetaData.photoUrl : null
    },
    pikleOnlineDomain () {
      return Constants.PIKLE_DOMAIN
    },
    showIntro: {
      // getter
      get: function () {
        return !this.hideIntroPreference
      },
      // setter
      set: function (newValue) {
        this.$store.dispatch('user/updateHideIntro', !newValue)
      }
    },
    userInitials () {
        let firstChar = "?"
        let secondChar = ""
        const theName = this.displayName || this.userName || null
        if (theName) {
          firstChar = theName.charAt(0)
          const displayNameWords = theName.split(' ')
          if (displayNameWords && displayNameWords.length > 1) {
            secondChar = displayNameWords[1].charAt(0)
          }
        } else if (this.email) {
          const emailName = this.email.split('@')[0]
          firstChar = emailName.charAt(0)
          const emailWords = emailName.split('.')
          if (emailWords && emailWords.length > 1) {
            secondChar = emailWords[1].charAt(0)
          }
        }

        return `${firstChar}${secondChar}`.toUpperCase()
      }
  },
  methods: {
    logout: function () {
      this.resetAllStoreModules()
      signOut(AuthUtil.auth).then(() => {
        if (this.$route.name !== 'Home') {
          this.$router.replace('/')
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.absolute {
  position: absolute;
  top: 0px;
  right: 16px;
}
.app-bar-avatar {
  margin-right: 0px !important;
}
// .user-menu {
//   background-color: #0e2887 !important;
//
//   .v-list {
//     background: none;
//   }
// }
</style>
