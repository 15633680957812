const PikleServiceStatusMixins = {
  methods: {
    getTaxonServiceMode: function(app, topLevelServiceModes, taxonId, serviceId) {
      let res = null
      if (app && app.services && app.services[serviceId] && app.services[serviceId].taxonModes && app.services[serviceId].taxonModes[taxonId]) {
        res = app.services[serviceId].taxonModes[taxonId]
      } else {
        // Defaults to the app level mode
        res = app && app.services && app.services[serviceId] ? app.services[serviceId].mode : null;
      }
      if (res) {
        res = topLevelServiceModes[serviceId][res] 
      }
      return res
    }
  }
}

export default PikleServiceStatusMixins
