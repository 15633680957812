import Constants from '../constants.js'

import AuthUtil from '../authUtil'
import axios from 'axios'

export default {

  async scrapeTaxonURLs (appId, appKey, taxonId, urls, criteria, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/scrape`
    const body = {urls: urls, criteria: criteria, taxon: taxonId, appKey: appKey}
    axios.post(developerAppsURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      // console.log(response)
      callback(response.data)
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
    .then(function () {
      // always executed
    })
  },

  async contentCheckTaxonURLs (appId, appKey, taxonId, urls, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/urlcheck`
    const body = {urls: urls, taxon: taxonId, appKey: appKey}

    axios.post(developerAppsURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      // console.log(response)
      callback(response.data)
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
    .then(function () {
      // always executed
    })
  },

  async checkAllValuesForTaxonURLs (appId, appKey, taxonId, urls, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/valuescheck`
    const body = {urls: urls, taxon: taxonId, appKey: appKey}

    axios.post(developerAppsURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      // console.log(response)
      callback(response.data)
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
    .then(function () {
      // always executed
    })
  },

  async scrapeSynonym (decisionId, optionId, appKey, url, criteriaItem, taxonRegion) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const scrapeSingleValueURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/criteria/scrape`

    const scrapeCriteriaList = [criteriaItem]

    const valuesOnlyScrapeBody = {
      url: encodeURI(url),
      criteria: scrapeCriteriaList,
      valuesOnlyScrape: true,
      uid: appKey,
      taxonRegion: taxonRegion
    }

    // console.log(`Calling values only scrape with body ${JSON.stringify(valuesOnlyScrapeBody)}`)
    return axios.post(scrapeSingleValueURL, valuesOnlyScrapeBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },

  async saveValues (appKey, taxon, optionId, criteriaValues) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const saveOptionValuesURL = `${Constants.API_BASE_PATH}/developer/optionvalues`

    const payload = {
      criteriaValues: criteriaValues,
      appKey: appKey,
      taxon: taxon,
      optionId: optionId
    }

    // console.log(`Calling values only scrape with body ${JSON.stringify(valuesOnlyScrapeBody)}`)
    return axios.post(saveOptionValuesURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
  },

  async approveOptionValues (decisionId, optionId, payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const approveOptionValuesURL = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/approve`

    const res = await axios.post(approveOptionValuesURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return true
    } else {
      return false
    }
  },

  async deleteOption (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteOptionUrl = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}`

    const res = await axios.delete(deleteOptionUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return true
    } else {
      return false
    }
  },

  async getOption (decisionId, optionId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const getOptionUrl = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}`

    const res = await axios.get(getOptionUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return res.data
    } else {
      return null
    }
  },

  async updateOptionName (decisionId, optionId, newName) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateOptionUrl = `${Constants.API_BASE_PATH}/decisions/${decisionId}/options/${optionId}/name`

    const res = await axios.post(updateOptionUrl, {name: newName}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200) {
      return res.data
    } else {
      return null
    }
  }

}
