<template>
  <v-app id="pikle">
    <v-main class="auth-progress-container">
      <v-container
        fluid
        class="fill-height align-start pt-6"
      >
        <v-row align="center" justify="center"
        >
          <v-container fluid class="d-flex justify-center progress-wrapper">
            <v-progress-circular
              color="white"
              indeterminate
              class="mt-6"
            ></v-progress-circular>
          </v-container>
          <!-- <v-progress-circular class="mt-6" indeterminate></v-progress-circular> -->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AuthUtil from '../authUtil'

import { signOut, signInWithCustomToken } from "firebase/auth";

export default {
  name: 'DevConsoleAuth',
  components: {
  },
  data () {
    return {
      attempts: 0,
      verificationFailed: false,
      errorMessage: null
    }
  },
  computed: {

  },
  mounted () {
    const customToken = this.$route.query.token
    this.verifyAndLogin(customToken)
  },
  methods: {
    verifyAndLogin: async function(token) {
      if (token) {
        this.verificationFailed = false
        // let self = this
        // let userAlreadySignedIn = false
        if (AuthUtil.auth.currentUser) {
          // Sign out other user
          await signOut(AuthUtil.auth)
        }
        // if (!userAlreadySignedIn) {
        // firebase.auth().signInWithCustomToken(token).then(
        signInWithCustomToken(AuthUtil.auth, token).then(
          (userCredential) => {
            const user = userCredential.user;
            if (user && !AuthUtil.auth.currentUser.emailVerified) {
              this.verificationFailed = true
            } else {
              this.$router.replace('/console')
            }
        }).catch((error) => {
          this.attempts++
          var errorCode = error.code
          var errorMessage = error.message
          this.errorMessage = `Error authenticating ${errorCode} : ${errorMessage}`
          // console.log(errorMessage)
        })
        // }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.auth-progress-container {
  background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.8) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/futureblocks.webp');
  background-size: 100% auto;
  background-position: top center;
  color: white;
}
.progress-wrapper {
  margin-top: 110px;
}
</style>
