// MUTATIONS
export const MUTATION_NEW_APP_DIALOG               = 'newAppDialogMutation'
export const MUTATION_NEW_COMPARE_CATEGORY_DIALOG  = 'newCompareCategoryDialogMutation'
export const MUTATION_NEW_TAXON_ITEMS_DIALOG        = 'addTaxonItemsDialogMutation'
export const MUTATION_NEW_ITEM_GROUP_DIALOG         = 'newItemGroupDialogMutation'
// export const MUTATION_SERVICE_MODE_DIALOG          = 'serviceModeDialogMutation'
export const MUTATION_SET_APPS                     = 'setAppsMutation'
export const MUTATION_SET_INVITATIONS                     = 'setInvitationsMutation'
export const MUTATION_ADD_APP                      = 'addAppMutation'
export const MUTATION_REMOVE_APP                   = 'removeAppMutation'
export const MUTATION_FETCHING_STATUS              = 'setFetchingStatusMutation'
export const MUTATION_FETCHING_APP_STATUS              = 'setFetchingAppStatusMutation'
export const MUTATION_FETCHING_FILTERS_STATUS              = 'setFetchingFiltersStatusMutation'
export const MUTATION_FETCHING_GROUPS_STATUS              = 'setFetchingGroupsStatusMutation'
export const MUTATION_SET_CURRENT_APP              = 'setCurrentApp'
export const MUTATION_SET_CURRENT_APP_TAXON              = 'setCurrentAppTaxon'
export const MUTATION_SET_CURRENT_TAXON_GROUP              = 'setCurrentAppTaxonGroup'
export const MUTATION_MODIFY_CURRENT_GROUP              = 'modifyCurrentAppTaxonGroup'
export const MUTATION_MODIFY_TAXON_GROUP              = 'modifyAppTaxonGroup'
export const MUTATION_SET_CURRENT_APP_TAXON_SUB_FILTER              = 'setCurrentAppTaxonSubFilter'
export const MUTATION_ADD_CURRENT_APP_TAXON        = 'addTaxonToCurrentAppMutation'
export const MUTATION_DELETE_CURRENT_APP_TAXON     = 'deleteTaxonFromCurrentAppMutation'
export const MUTATION_SET_TAXON_COUNTS     = 'updateTaxonCountsFromCurrentAppMutation'
export const MUTATION_SET_CURRENT_TAXON_SCORE       = 'setCurrentAppTaxonScore'
export const MUTATION_SET_CURRENT_TAXON_SCORE_MESSAGES       = 'setCurrentAppTaxonScoreMessages'
export const MUTATION_SET_CURRENT_APP_FEED_SIZE       = 'setCurrentAppFeedSize'
export const MUTATION_SET_CURRENT_APP_MISSING_IN_FEED_COUNT       = 'setCurrentAppMissingInFeedCount'
export const MUTATION_SET_TAXON_CRITERIA           = 'setTaxonCriteriaMutation'
export const MUTATION_SET_TAXON_URLS           = 'setTaxonURLsMutation'
export const MUTATION_SET_TAXON_ML_SETTINGS           = 'setTaxonMLSettingsMutation'
export const MUTATION_SET_TAXON_SELECTORS           = 'setTaxonSelectorsMutation'
export const MUTATION_SET_TAXON_GROUPS              = 'setCurrentTaxonGroupsMutation'

export const MUTATION_SET_TAXON_FILTERS              = 'setCurrentTaxonFiltersMutation'
export const MUTATION_ADD_TAXON_FILTER              = 'addCurrentTaxonFilterMutation'
// export const MUTATION_UPDATE_FILTERS_TAXON              = 'updateFiltersTaxonStatusMutation'
export const MUTATION_UPDATE_TAXON_FILTER_VISIBILITY              = 'updateCurrentTaxonFilterVisibilityMutation'

export const MUTATION_SET_TAXON_ITEMS               = 'setCurrentTaxonItemsMutation'
export const MUTATION_SET_TAXON_ITEM_GROUPS    = 'setCurrentTaxonItemGroupsMutation'
export const MUTATION_SET_TAXON_ITEM_GROUP_NAMES    = 'setCurrentTaxonItemGroupNamesMutation'
export const MUTATION_SET_TAXON_ITEM_DATA    = 'setCurrentTaxonItemDataMutation'
export const MUTATION_REMOVE_TAXON_ITEM    = 'removeCurrentTaxonItemMutation'
export const MUTATION_SET_SERVICE_SETTINGS          = 'setServiceSettingsMutation'
export const MUTATION_SET_CURRENT_COMPARE_SERVICE_TAB       = 'setCurrentCompareServiceTab'
export const MUTATION_SET_CURRENT_APP_DELETING      = 'setCurrentAppDeleting'
export const MUTATION_SET_CURRENT_APP_DELETED      = 'setCurrentAppDeleted'
export const MUTATION_SET_DELETING_TAXON      = 'setDeletingtaxonMutation'
export const MUTATION_SET_APP_DOMAIN_VERIFIED_STATUS  = 'setAppDomainVerifiedStatusMutation'
export const MUTATION_EDIT_TAXON_ITEM_DIALOG        = 'editTaxonItemDialogMutation'
// export const MUTATION_ADD_TAXON_CRITERIA        = 'addTaxonCriteriaMutation'
// export const MUTATION_REMOVE_TAXON_CRITERIA     = 'removeTaxonCriteriaMutation'
// export const MUTATION_TAXON_CRITERIA_SET_KEYWORD_ONLY  = 'taxonCriteriaSetKeywordOnly'

export const MUTATION_SET_REFRESHING_TAXON                      = 'setRefreshingTaxonMutation'
export const MUTATION_SET_LAST_TAXON_REFRESH_COUNT                      = 'setLastTaxonRefreshCountMutation'

export const MUTATION_SET_APP_STORE_DETAILS          = 'setAppStoreDetails'

export const MUTATION_SET_APP_NAME              = 'setAppName'

export const MUTATION_SET_APP_GPT_CONFIG          = 'setAppGPTCOnfig'

// Sets data in the apps members map (ids and roles)
export const MUTATION_SET_MEMBER                    = 'setAppMemberMutation'
export const MUTATION_UNSET_MEMBER                 = 'unsetAppMemberMutation'

// Subscrive to get latest data for the current app
export const MUTATION_SET_APP_UNSUBSCRIBE_CALLBACK  = 'setAppUnsubscribeCallbackMutation'

export const MUTATION_SET_APP_TAXONS_UNSUBSCRIBE_CALLBACK  = 'setAppTaxonsUnsubscribeCallbackMutation'

// Subscribe to get latest user data for members
export const MUTATION_ADD_MEMBERS_UNSUBSCRIBE_CALLBACK  = 'setMembersUnsubscribeCallbackMutation'
export const MUTATION_CLEAR_MEMBERS_UNSUBSCRIBE_CALLBACKS  = 'clearMembersUnsubscribeCallbackMutation'
// Manage current members public data
export const MUTATION_CLEAR_MEMBERS                    = 'clearMembersMutation'
export const MUTATION_ADD_MEMBER                    = 'addMemberMutation'
export const MUTATION_REMOVE_MEMBER                 = 'removeMemberMutation'

export const MUTATION_UPDATE_CATALOG_ITEMS_COUNT                 = 'updateCatalogItemsCountMutation'
export const MUTATION_UPDATE_PAGINATED_ITEMS_COUNT                 = 'updatePaginatedCatalogItemsCountMutation'
export const MUTATION_UPDATE_TAXON_ITEMS_COUNT                 = 'updateTaxontemsCountMutation'

export const MUTATION_SET_NAV_ITEM_EXPANDED                 = 'setNavItemExpandedMutation'


export const MUTATION_SET_SYNC_CATALOG_META_DATA           = 'setSyncCatalogMetaDataEnabledMutation'

// ACTIONS
export const FETCH_DEVELOPER_APP              = 'fetchDeveloperApp'
export const ACTION_RESET_TAXON_STATE         = 'resetTaxonDataState'
export const REFRESH_DEVELOPER_APP            = 'refreshDeveloperApp'
export const FETCH_DEVELOPER_APPS              = 'fetchDeveloperApps'
export const FETCH_APPS_INVITATIONS              = 'fetchAppInvitations'
export const FETCH_CURRENT_TAXON_GROUPS       = 'fetchCurrentTaxonGroups'
export const FETCH_CURRENT_TAXON_FILTERS       = 'fetchCurrentTaxonFilters'
export const FETCH_ALL_TAXON_FILTERS       = 'fetchAllTaxonFilters'
export const ADD_NEW_TAXON_FILTER       = 'addNewTaxonFilter'
export const ADD_EXISTING_TAXON_FILTER       = 'updateTaxonFilter'

export const SET_TAXON_FILTER_VISIBILITY       = 'updateTaxonFilterVisibility'

export const FETCH_CURRENT_TAXON_ITEMS       = 'fetchCurrentTaxonItems'
export const CREATE_NEW_DEVELOPER_APP          = 'createNewDeveloperApp'
export const CREATE_NEW_APP_TAXON              = 'createNewAppTaxon'
export const UPDATE_TAXON_CRITERIA              = 'updateTaxonCriteria'
export const UPDATE_TAXON_URLS              = 'updateTaxonURLs'
export const UPDATE_TAXON_ML_SETTINGS           = 'updateTaxonMLSettings'
export const UPDATE_CURRENT_GROUP               = 'updateCurrentGroup'
export const UPDATE_TAXON_GROUP                 = 'updateTaxonGroup'
export const ACTION_SET_CURRENT_APP_TAXON           = 'setCurrentAppTaxon'
export const ACTION_SET_CURRENT_APP_TAXON_GROUP = 'setCurrentAppTaxonGroup'
export const DELETE_CURRENT_APP_TAXON           = 'deleteCurrentAppTaxon'
export const DELETE_CURRENT_APP_TAXON_ITEM           = 'deleteCurrentAppTaxonItem'
export const DELETE_CURRENT_APP_TAXON_ITEMS           = 'deleteCurrentAppTaxonItems'
export const UPDATE_APP_COMPARISON_AUTO_REFRESH_STATE        = 'updateComparisonAutoRefrehs'
export const UPDATE_APP_COMPARISON_AUTO_IMPORT_STATE        = 'updateComparisonAutoImport'
export const UPDATE_APP_COMPARISON_AUTO_APPROVE_STATE       = 'updateComparisonAutoApprove'
export const UPDATE_APP_SEARCH_TAXON_AUTO_IMPORT_STATE            = 'updateSearchTaxonAutoImport'
export const UPDATE_APP_SEARCH_IMPORT_ALL_STATE            = 'updateSearchImportAll'
export const UPDATE_APP_SERVICE_MODE          = 'updateServiceMode'
export const FETCH_CURRENT_APP_TAXON_SELECTORS   = 'fetchCurrentAppTaxonSelectors'
export const DELETE_CURRENT_APP              = 'deleteDeveloperApp'
export const ACTION_INVALIDATE_TAXON          = 'invalidateTaxon'
export const ACTION_SET_APP_DOMAIN_VERIFIED   = 'setAppDomainVerified'
export const ACTION_CALCULATE_CURRENT_TAXON_SCORE   = 'calculateCurentTaxonScore'

export const ACTION_UPDATE_APP_STORE_DETAILS   = 'updateAppStoreDetails'
export const ACTION_UPDATE_APP_NAME             = 'updateAppName'

export const ACTION_LOAD_APP_MEMBERS              = 'loadAppMembers'

export const ACTION_SET_SYNC_CATALOG_META_DATA    = 'setSyncCatalogProductMetaData'

// TODO add action to set current taxon. Clear taxon specific values from the store and then commit taxon
