import { onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";

import Vue from 'vue'
// import Rollbar from 'vue-rollbar'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify.js'

import FirestoreUtil from './firestoreUtil'
import AuthUtil from './authUtil'
import AnalyticsUtil from './analyticsUtil'

import userAPI from './api/user'

import InstantSearch from "vue-instantsearch"

Vue.config.productionTip = false

Vue.use(InstantSearch);

// Vue.use(Rollbar, {
//      accessToken: '47edc167a82d4e9a83155143ef6cf34b',
//      captureUncaught: true,
//      captureUnhandledRejections: true,
//      enabled: true,
//      source_map_enabled: true,
//      environment: 'production',
//      payload: {
//        client: {
//             javascript: {
//                code_version: '1.0'
//             }
//        }
//      }
// });

// Vue.config.errorHandler = err => {
//   if (process.env.NODE_ENV === 'production') {
//     Vue.rollbar.error(err);
//   }
// }

// NOTE: These are OK to have here and are considered public.
// See https://firebase.google.com/docs/projects/learn-more#config-files-objects
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

// Optional measurement id
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
FirestoreUtil.init(firebaseApp)
AuthUtil.init(firebaseApp)
AnalyticsUtil.init(firebaseApp)

let app = ''

// Initialize Analytics and get a reference to the service
if (process.env.VUE_APP_FIREBASE_MEASUREMENT_ID) {
  getAnalytics(firebaseApp);
}

onAuthStateChanged(AuthUtil.auth, () => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  // Record auth state change in usage stats
  if (AuthUtil.auth.currentUser) {
    userAPI.recordUsageStats(AuthUtil.auth.currentUser.uid, 'authStateChangedUserActive')
  }
})
