const CONSTANTS = {
    PIKLE_ENV: process.env.VUE_APP_PIKLE_ENV,
    API_BASE_PATH: process.env.VUE_APP_API_BASE_PATH,
    ASSISTANT_API_BASE_PATH: process.env.VUE_APP_ASSISTANT_API_BASE_PATH,
    PIKLE_DOMAIN: process.env.VUE_APP_PIKLE_DOMAIN,
    SCRAPE_STATES:  {
      unscraped: 0,
      scraped: 1,
      approved: 2,
      validating: 3,
      scraping: 4,
      failed: 5
    }
}

export default CONSTANTS
