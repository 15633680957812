<template>
  <v-app id="pikle">
    <v-main class="content-container">
      <v-container class="container">
        <h1 class="white--text mb-4">Market Insights</h1>
        <v-row align="center" justify="center"
        >
          <div class="pricing-panel">
            <div class="pricing-table">
              <div class="pricing-plan">
                <div class="pricing-img align-center">
                  <!-- <v-img
                    :height="planImageHeight"
                    :src="require('@/assets/free-plan.png')"
                    class="pricing-img"
                    contain
                  >
                    <div class="pricing-plan-title mt-6 mx-2">
                      Build and test Comparison & filtering for free
                    </div>
                  </v-img> -->
                </div>
                <!-- <img src="https://s21.postimg.cc/tpm0cge4n/space-ship.png" alt="" class="pricing-img"> -->
                <div class="pricing-plan-title">
                  <!-- <h2>TEST</h2> -->
                </div>
                <ul class="pricing-features labels">
                  <!-- <li class="pricing-features-item align-left large heading">PRODUCT COMPARISON & FILTERING</li>
                  <li v-if="!showDetails" class="pricing-features-item align-left summary-row">
                    <v-btn x-small color="secondary" outlined @click="toggleShowDetails()"><v-icon x-small>mdi-chevron-down</v-icon> Plan Details</v-btn>
                  </li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">PRODUCTS INCLUDED&ast;</li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">CATALOG FEED</li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">UI INTEGRATION VIA JS SDK</li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">CHANGE DETECTION OPTIONS</li>
                  <li class="pricing-features-item align-left large heading">MARKET INSIGHTS</li> -->
                  <li v-if="!showDetails" class="pricing-features-item align-left summary-row">
                    <v-btn x-small color="secondary" outlined @click="toggleShowDetails()"><v-icon x-small>mdi-chevron-down</v-icon> Plan Details</v-btn>
                  </li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">MATCHED PRODUCTS INCLUDED&ast; &dagger;</li>
                  <li v-if="showDetails" class="pricing-features-item align-left">MAX COMPETITORS</li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">PRICES UP TO DATE WITHIN 12 HOURS</li>
                  <li v-if="showDetails" class="pricing-features-item align-left large">AUTOMATCH PRODUCTS ON MULTIPLE CODES</li>
                  <li v-if="showDetails" class="pricing-features-item large align-left">REALTIME PRICES CHECKS INCLUDED&ast;</li>
                </ul>
                <div class="price-details text-left">
                  <v-btn v-if="showDetails" x-small color="secondary" outlined @click="toggleShowDetails()">
                    <v-icon x-small>mdi-chevron-up</v-icon> Summarize the plans
                  </v-btn>
                </div>
              </div>

              <div class="pricing-plan">
                <div class="pricing-img">
                  <v-img
                    :height="planImageHeight"
                    :src="require('@/assets/start-plan.png')"
                    class="pricing-img"
                    contain

                  ></v-img>
                </div>
                <div class="pricing-plan-title">
                  <h2>Start</h2>
                </div>
                <ul class="pricing-features">
                  <!-- <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.startPlan.summaryComparison}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.startPlan.products}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="red" small>mdi-close</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large small-text">Daily</li>
                  <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li> -->
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.startPlan.summaryMonitoring}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.startPlan.matchingSKUs}}</li>
                  <li v-if="showDetails" class="pricing-features-item">{{this.startPlan.competitors}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.startPlan.realtimePriceChecks}}</li>
                </ul>
                <div class="price-details">
                  Starting from <span class="pricing-price">${{this.startPlan.basePrice}} USD</span> + additional matched products & real-time checks
                </div>
                <a href="#/" class="pricing-button" :class="{'is-recommended': showCalculator && recommendedPlan && recommendedPlan.id === 'startPlan'}">{{callToAction}}</a>
                <div class="price-details calculated" v-if="showCalculator">
                  Calculated price <span class="pricing-price" :class="{'is-recommended': recommendedPlan && recommendedPlan.id === 'startPlan'}">${{calculatePlanCosts(startPlan).toFixed(2)}} USD</span>
                </div>
              </div>

              <div class="pricing-plan">
                <div class="pricing-img">
                  <v-img
                    :height="planImageHeight"
                    :src="require('@/assets/grow-plan.png')"
                    class="pricing-img"
                    contain

                  ></v-img>
                </div>
                <div class="pricing-plan-title">
                  <h2>Grow</h2>
                </div>
                <ul class="pricing-features">
                  <!-- <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.growPlan.summaryComparison}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.growPlan.products}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large small-text">Daily | Real-time</li>
                  <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li> -->
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.growPlan.summaryMonitoring}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.growPlan.matchingSKUs}}</li>
                  <li v-if="showDetails" class="pricing-features-item">{{this.growPlan.competitors}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.growPlan.realtimePriceChecks}}</li>
                </ul>
                <div class="price-details">
                  Starting from <span class="pricing-price">${{this.growPlan.basePrice}} USD</span> + additional matched products & real-time checks
                </div>
                <a href="#/" class="pricing-button" :class="{'is-recommended': showCalculator && recommendedPlan && recommendedPlan.id === 'growPlan'}">{{callToAction}}</a>
                <div class="price-details calculated" v-if="showCalculator">
                  Calculated price <span class="pricing-price" :class="{'is-recommended': recommendedPlan && recommendedPlan.id === 'growPlan'}">${{calculatePlanCosts(growPlan).toFixed(2)}} USD</span>
                </div>
              </div>

              <div class="pricing-plan">
                <div class="pricing-img">
                  <v-img
                    :height="planImageHeight"
                    :src="require('@/assets/pro-plan.png')"
                    class="pricing-img"
                    contain

                  ></v-img>
                </div>
                <div class="pricing-plan-title">
                  <h2>Pro</h2>
                </div>
                <ul class="pricing-features">
                  <!-- <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.proPlan.summaryComparison}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.proPlan.products}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large small-text">Daily | Real-time</li>
                  <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li> -->
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.proPlan.summaryMonitoring}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.proPlan.matchingSKUs}}</li>
                  <li v-if="showDetails" class="pricing-features-item">{{this.proPlan.competitors}}</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.proPlan.realtimePriceChecks}}</li>
                </ul>
                <div class="price-details">
                  Starting from <span class="pricing-price">${{this.proPlan.basePrice}} USD</span> + additional matched products & real-time checks
                </div>
                <a href="#/" class="pricing-button" :class="{'is-recommended': showCalculator && recommendedPlan && recommendedPlan.id === 'proPlan'}">{{callToAction}}</a>
                <div class="price-details calculated" v-if="showCalculator">
                  Calculated price <span class="pricing-price" :class="{'is-recommended': recommendedPlan && recommendedPlan.id === 'proPlan'}">${{calculatePlanCosts(proPlan).toFixed(2)}} USD</span>
                </div>
              </div>

              <div class="pricing-plan">
                <div class="pricing-img">
                  <v-img
                    :height="planImageHeight"
                    :src="require('@/assets/enterprise-plan.png')"
                    class="pricing-img"
                    contain

                  ></v-img>
                </div>
                <div class="pricing-plan-title">
                  <h2>Enterprise</h2>
                </div>
                <ul class="pricing-features">
                  <!-- <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.enterprisePlan.summaryComparison}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.enterprisePlan.products}}&ast;&ast;</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large small-text">Daily | Real-time | API</li>
                  <li class="pricing-features-item large heading"><v-icon color="green" small>mdi-check</v-icon></li> -->
                  <li v-if="!showDetails" class="pricing-features-item summary-row">{{this.enterprisePlan.summaryMonitoring}}</li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.enterprisePlan.matchingSKUs}}&ast;&ast;</li>
                  <li v-if="showDetails" class="pricing-features-item">{{this.enterprisePlan.competitors}}&ast;&ast;</li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large"><v-icon color="green" small>mdi-check</v-icon></li>
                  <li v-if="showDetails" class="pricing-features-item large">{{this.enterprisePlan.realtimePriceChecks}}</li>
                </ul>
                <div class="price-details">
                  Starting from <span class="pricing-price">${{this.enterprisePlan.basePrice}} USD</span> + additional matched products & real-time checks
                </div>
                <a href="#/" class="pricing-button" :class="{'is-recommended': showCalculator && recommendedPlan && recommendedPlan.id === 'enterprisePlan'}">{{callToAction}}</a>
                <div class="price-details calculated" v-if="showCalculator">
                  Calculated price <span class="pricing-price" :class="{'is-recommended': recommendedPlan && recommendedPlan.id === 'enterprisePlan'}">${{calculatePlanCosts(enterprisePlan).toFixed(2)}} USD</span>
                </div>
              </div>

            </div>
            <div class="pricing-calculater">
              <div class="d-flex align-center mb-3">
                <h3 class="blue--text">Pricing Calculater</h3>
                <v-btn class="ml-4" x-small color="secondary" outlined @click="toggleCalculator()">
                  <v-icon x-small>mdi-calculator</v-icon> {{ showCalculator ? 'Hide' : 'Show' }} calculator
                </v-btn>
              </div>
              
              <div class="d-flex align-start" v-if="showCalculator">
                <div style="width: 100%; border-right: 1px solid #e1f1ff;">
                  <!-- <h4 class="mb-2">Comparison & Filtering</h4>
                  <div class="text-h7 font-weight-light">Comparable & Filterable Products</div>
                  <div class="d-flex align-center">
                    <div
                      :style="'width: 100px;'"
                    >
                      <v-text-field
                        v-model="comparableProducts"
                        class="mt-0 mr-2 pt-0"
                        type="number"
                        solo
                        dense
                      ></v-text-field>
                    </div>
                    <v-slider
                      style="height: 30px;"
                      class="mx-2"
                      v-model="comparableProducts"
                      thumb-color="blue"
                      track-color="grey"
                      min="0"
                      :max="maxProducts"
                    ></v-slider>
                  </div>
                </div>
                <div style="width: 100%; border-right: 1px solid #e1f1ff; margin-left: 10px;"> -->
                  <!-- <h4 class="mb-2">Market Insights</h4> -->
                  <div class="text-h7 font-weight-light">Monitored products with market insights</div>
                  <div class="d-flex align-center">
                    <div
                      :style="'width: 100px;'"
                    >
                      <v-text-field
                        v-model="monitoredSKUs"
                        class="mt-0 mr-2 pt-0"
                        type="number"
                        solo
                        dense
                      ></v-text-field>
                    </div>
                    <v-slider
                      style="height: 30px;"
                      class="mx-2"
                      v-model="monitoredSKUs"
                      thumb-color="blue"
                      track-color="grey"
                      min="0"
                      :max="maxMonitoredSKUs"
                    ></v-slider>
                  </div>
                  <div class="text-h7 font-weight-light">Monthly Realtime Price Checks</div>
                  <div class="d-flex align-center">
                    <div
                      :style="'width: 100px;'"
                    >
                      <v-text-field
                        label="Monthly Realtime Checks"
                        v-model="projectedRealtimeChecks"
                        class="mt-0 mr-2 pt-0"
                        type="number"
                        solo
                        dense
                      ></v-text-field>
                    </div>
                    <v-slider
                      style="height: 30px;"
                      class="mx-2"
                      v-model="projectedRealtimeChecks"
                      thumb-color="blue"
                      track-color="grey"
                      min="0"
                      :max="maxRealtimeChecks"
                    ></v-slider>
                  </div>
                </div>
                <div style="width: 310px;">
                  <div class="px-6 ml-3">
                    <div
                      class="calc-price"
                    >
                      ${{calculatedPrice}} USD/month
                    </div>
                    <div class="text-caption font-weight-light mt-2">Estimate price based on optimal plan selection and the number of matched products and Realtime Price Checks being used.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pricing-notes font-weight-light">
              <div>
                All prices are per App / ecommerce domain.
              </div>
              <div>
                <span class="annotation">&ast;</span> Aditional comparable & filterable products charged at ${{costPerExtraComparableProduct}}. Aditional matching matched products charged at ${{costPerExtraMonitoredSKU}}. Aditional price checks charged at ${{costPerExtraPriceCheck}} per check.
              </div>
              <div>
                <span class="annotation">&dagger;</span> Only products that match 1 or more competitors count towards the matching product limits.
              </div>
              <div>
                <span class="annotation">&ast;&ast;</span> Contact us if you need to manage more than 15,000 comparable & filterable products or more than 8,000 matched products. We can work out a custom pricing plan that suits you.
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'PikleEcommercePricing',
  components: {
  },
  data () {
    return {
      planImageHeight: 100,
      maxProducts: 20000,
      comparableProducts: 50,
      comparableCategories: 1,
      maxCategories: 100,
      monitoredSKUs: 0,
      maxMonitoredSKUs: 10000,
      maxRealtimeChecks: 1000,
      projectedRealtimeChecks: 0,
      showDetails: false,
      startPlan: {
        id: 'startPlan',
        competitors: 1,
        realtimePriceChecks: 0,
        categories: 4,
        products: 250,
        matchingSKUs: 50,
        domains: 1,
        basePrice: 49,
        summaryComparison: 'incl. 250 comparable & filterable products',
        summaryMonitoring: 'incl. 50 products matched against 1 competitor',
        nextPlan: 'growPlan'
      },
      growPlan: {
        id: 'growPlan',
        competitors: 4,
        realtimePriceChecks: 100,
        categories: 10,
        products: 1500,
        matchingSKUs: 1000,
        domains: 1,
        basePrice: 99,
        summaryComparison: 'incl. 1,500 comparable & filterable  products',
        summaryMonitoring: 'incl. 1,000 products matched against 4 competitors',
        nextPlan: 'proPlan'
      },
      proPlan: {
        id: 'proPlan',
        competitors: 10,
        realtimePriceChecks: 300,
        categories: 40,
        products: 5000,
        matchingSKUs: 2000,
        domains: 2,
        basePrice: 199,
        summaryComparison: 'incl. 5,000 comparable & filterable  products',
        summaryMonitoring: 'incl. 2,000 products matched against 10 competitors',
        nextPlan: 'enterprisePlan'
      },
      enterprisePlan: {
        id: 'enterprisePlan',
        competitors: 20,
        realtimePriceChecks: 900,
        categories: 100,
        products: 15000,
        matchingSKUs: 8000,
        domains: 4,
        basePrice: 299,
        summaryComparison: 'incl. 15,000 comparable & filterable  products',
        summaryMonitoring: 'incl. 7,000 products matched against 20 competitors',
      },
      callToAction: 'coming soon',//'enquire now',
      costPerExtraComparableProduct: 0.15,
      costPerExtraMonitoredSKU: 0.15,
      costPerExtraPriceCheck: 0.10,
      // costPerExtraFilterable: 0.05,
      comparablesCost: 0,
      filterableCosts: 0,
      basePrice: 0,
      calculatorPlan: null,
      showCalculator: false
      // supportFiltering: false
    }
  },
  computed: {
    recommendedPlan () {
      let res = this.selectedPlan ? this.selectedPlan : this.startPlan

      if (!this.selectedPlan) {
        
        if (this.calculatePlanCosts(this.enterprisePlan) < this.calculatePlanCosts(this.proPlan)) {
          res = this.enterprisePlan
        }
        else if (this.calculatePlanCosts(this.proPlan) < this.calculatePlanCosts(this.growPlan)) {
          res = this.proPlan
        }
        else if (this.calculatePlanCosts(this.growPlan) < this.calculatePlanCosts(this.startPlan)) {
          res = this.growPlan
        }
      }

      return res;
    },
    calculaterPlanOptions () {
      return [
        {id: 'recommendedPlan', title: 'Recommended Plan'},
        this.startPlan,
        this.growPlan,
        this.proPlan,
        this.enterprisePlan
      ]
    },
    selectedPlan () {
      return this.calculatorPlan && this.calculatorPlan.id !== 'recommendedPlan' ? this.calculatorPlan : null;
    },
    calculatedPrice () {

      let res = 0
      

      if (this.selectedPlan) {
        res = this.calculatePlanCosts(this.selectedPlan)
      } else {

        res = this.calculatePlanCosts(this.recommendedPlan)

        // const constInStartPlan = this.calculatePlanCosts(this.startPlan)
        // const constInGrowPlan = this.calculatePlanCosts(this.growPlan)
        // const constInProPlan = this.calculatePlanCosts(this.proPlan)
        // const constInEntPlan = this.calculatePlanCosts(this.enterprisePlan)

        // res = constInStartPlan

        // if (this.comparableProducts >= this.startPlan.products || this.comparableCategories >= this.startPlan.categories || (this.monitoredSKUs > this.startPlan.matchingSKUs && this.monitoredSKUs < this.growPlan.matchingSKUs)) {
        //   res = constInGrowPlan
        // }
        // if (this.comparableProducts >= this.growPlan.products || this.comparableCategories >= this.growPlan.categories || (this.monitoredSKUs > this.growPlan.matchingSKUs && this.monitoredSKUs < this.proPlan.matchingSKUs)) {
        //   res = constInProPlan
        // }
        // if (this.comparableProducts >= this.proPlan.products || this.comparableCategories >= this.proPlan.categories || (this.monitoredSKUs > this.proPlan.matchingSKUs && this.monitoredSKUs <= this.enterprisePlan.matchingSKUs)) {
        //   res = constInEntPlan
        // }
      }

      return res.toFixed(2);
    }
  },
  methods: {
    calculateExtraCosts: function (comparableProducts, monitoredSKUs, priceChecksCount, plan, costPerExtraComparableProduct, costPerExtraMonitoredSKU, costPerExtraPriceCheck) {
      // const initPlan = this.supportFiltering ? this.growPlan : this.startPlan;

      let res = 0//productsCount > 0 ? initPlan.basePrice : 0;
      const priceChecksDiff = priceChecksCount - plan.realtimePriceChecks
      if (priceChecksDiff > 0) {
        res = priceChecksDiff * costPerExtraPriceCheck
      }

      const monitoredDiff = monitoredSKUs - plan.matchingSKUs
      if (monitoredDiff > 0) {
        res += monitoredDiff * costPerExtraMonitoredSKU
      }

      const comparableProductsDiff = comparableProducts - plan.products
      if (comparableProductsDiff > 0) {
        res += comparableProductsDiff * costPerExtraComparableProduct
      }

      return res
    },
    calculatePlanCosts: function (plan) {
      // const largestProductsCount = Math.max(this.comparableProducts, this.filterableProducts)
      // const priceChecksPerMonth = (this.comparableProducts * this.competitors) * 30
      // let basePrice = priceChecksPerMonth > 0 ? plan.basePrice : this.startPlan.basePrice;
      const extraPriceCheckCosts = this.calculateExtraCosts(this.comparableProducts, this.monitoredSKUs, this.projectedRealtimeChecks, plan, this.costPerExtraComparableProduct, this.costPerExtraMonitoredSKU, this.costPerExtraPriceCheck)
      // const extraFilterableCosts = this.calculateExtraCosts(this.filterableProducts, plan, this.costPerExtraFilterable)
      // competitors

      // return basePrice + extraComparableCosts + extraFilterableCosts
      return plan.basePrice + extraPriceCheckCosts
    },
    toggleShowDetails: function() {
      this.showDetails = !this.showDetails
    },
    toggleCalculator: function () {
      this.showCalculator = !this.showCalculator
      if (!this.showCalculator) {
        // Clear values
        this.comparableProducts = 50
        this.comparableCategories = 1
      
        this.monitoredSKUs = 0
        this.projectedRealtimeChecks = 0
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-container {
  // background: #0e2887;
  // background: linear-gradient(#3a52b5, #0e2887); /* W3C */
  // color: white;

  // background: #1c0c81 url('~@/assets/logo-bkg-repeat.png') top left !important;
  background-image: linear-gradient(-20deg, rgba(0, 16, 160, 0.3) 30%, rgba(166, 0, 255, 0.8) 60%), url('~@/assets/homepage/digitalcubes.webp');
  background-size: cover;
  background-position: center;
  max-height: 400px;
  overflow-y: visible;
}

.container {
  margin: 0 auto;
  padding: 50px 0 100px 0;
  max-width: 960px;
  width: 100%;
}

.pricing-panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
}

.pricing-table {
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 10px 0 25px 0;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 5px;
  max-width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.pricing-plan-title {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  height: 30px;
  font-size: 0.9rem;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small-text {
    font-size: 10px;
    line-height: 1.8;
  }

  &.large {
    min-height: 60px;
  }

  &.heading {
    background: #f6f8ff;
    color: #666;
  }

  &.align-left {
    justify-content: flex-start;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-features {
  color: #016FF9;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0 20px 0;
  padding: 0;
  list-style-type: none;

  &.labels {
    color: #999;

    .pricing-features-item {
      text-transform: none;
    }
  }

  .summary-row {
    text-transform: initial;
    color: #666;
    min-height: 70px;
    font-weight: 500;
    padding: 0 5px;
  }
}

.price-details {
  font-size: 12px;
  padding: 0 15px;

  .pricing-price {
    color: #016FF9;
    display: block;
    font-size: 24px;
    font-weight: 700;
  }

  &.calculated {

    font-size: 10px;


    .pricing-price {
      color: #016FF9;
      display: block;
      font-size: 12px;
      font-weight: 500;

      &.is-recommended {
        font-size: 18px;
      }
    }

  }
}

.pricing-calculater {
  border-top: 1px solid #CCC;
  padding-top: 20px;

  .calc-price {
    color: #016FF9;
    font-size: 24px;
    font-weight: 700;
  }
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348EFE;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-recommended {
  background-color: #48aaff;
  color: #fff;
}

.pricing-button.is-recommended:hover,
.pricing-button.is-recommended:active {
  background-color: #269aff;
}

.pricing-notes {
  margin-top: 60px;

  border-top: 1px solid #CCC;
  padding-top: 10px;
  color: #AAA;
  letter-spacing: 1px;

  .annotation {
    color: #016FF9;
  }
}

</style>
