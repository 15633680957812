// MUTATIONS
export const MUTATION_SET_TRAINED_DECISION_ID                      = 'setTrainedDecisionIdMutation'
export const MUTATION_SET_TRAINED_OPTION                      = 'setTrainedOptionMutation'
export const MUTATION_SET_TRAINING                      = 'setTrainingMutation'
export const MUTATION_SET_CHECKING_CONTENT                      = 'setCheckingContentMutation'
export const MUTATION_SET_OPTION_IS_TRAINING                      = 'setOptionIsTrainingMutation'
export const MUTATION_SET_OPTION_IS_SCRAPING_SINGLE_VALUE         = 'setOptionIsTrainingSynonymMutation'
export const MUTATION_SET_IMPORTING                      = 'setImportingMutation'

// ACTIONS
export const ACTION_RUN_TEST                  = 'runOptionTest'
export const ACTION_BULK_IMPORT                  = 'runOptionBulkImport'
export const ACTION_BULK_CONTENT_CHECK            = 'runBulkContentCheck'
export const ACTION_BULK_ALL_VALUES_CHECK          = 'runBuildValuesCheck'
// export const ACTION_CHECK_OPTION_SCRAPE_STATE = 'checkOptionScrapState'
