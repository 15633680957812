<template>
  <v-navigation-drawer
    permanent
    app
    :mini-variant="miniMenu"
    class="app-nav-drawer"
    dark
    color="#7900bb"
  >
    <template v-slot:img>
      <v-img :src="require('@/assets/menubackground.webp')"></v-img>
    </template>
    <v-list class="app-page-list" dense dark>
      <v-list-item :to="'/console'" class="nav-header-item">
        <div class="d-flex align-center justify-space-between" style="width: 100%;">
          <v-list-item-content v-if="!miniMenu">
            <v-img
              alt="Pikle Logo"
              :src="require('@/assets/logo-full-light.svg')"
              max-width="65"
              class="my-1"
            ></v-img>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
        </div>
      </v-list-item>
    </v-list>
    <!-- <v-divider></v-divider> -->
    <v-list class="app-page-list" dense dark>
      <v-list-item-group
        color="blue lighten-2"
      >
        <div class="nav-section">

          <v-list-item
              :to="{ name: 'App', params: { appId: currentAppId }}"
              exact
              class="top-level-item"
              @click="resetTopNav"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">
              
              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title>App Home</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>

            </div>
          </v-list-item>          

        </div>

        <div class="nav-section">
          <v-list-item
            exact
            :to="{ name: 'AppPikleCategories', params: { appId: currentAppId }}"
            class="top-level-item"
            :class="{'v-list-item--active': currentTaxon && (currentRouteName == 'AppTaxonOverview' || currentRouteName == 'AppTaxonGroupedOverview')}"
            @click="resetTopNav"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title>Pikle Taxonomy</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-shape</v-icon>
              </v-list-item-icon>
              
            </div>
          </v-list-item>

          <!-- <v-list-item
            :to="{ name: 'AppTaxonOverview', params: { appId: currentAppId, taxonId: this.currentTaxon.id }}"
            exact
            class="top-level-item child"
            v-if="currentTaxon && routeVisibleIn('AppPikleCategories')"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title class="ml-4">{{currentTaxon.name}}</v-list-item-title>
              </v-list-item-content>
              
            </div>
          </v-list-item> -->

          <!-- <div v-if="currentTaxon && routeVisibleIn('AppPikleCategories')" class="current-taxon-wrapper">
            <v-treeview
              activatable
              dense
              hoverable
              :items="currentTaxonNavItems"
              :active="activeCategoryNav"
              :open="openNavItems"
              @update:active="categoryNavChanged"
              class="child-nav-item tree-nav top-level-child"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.icon && item.type !== 'taxon' && item.type !== 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:append="{ item }">
                <v-icon x-small class="pa-0 ml-0 mr-5" v-if="item.type === 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
              
            </v-treeview>
          </div> -->

        </div>

        <div class="nav-section">
          <v-list-item
            :to="{ name: 'AppCatalog', params: { appId: currentAppId }}"
            exact
            class="top-level-item last-item"
            @click="resetTopNav"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title>Catalog Overview</v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-format-list-bulleted-type</v-icon>
              </v-list-item-icon>
              
            </div>
          </v-list-item>
        </div>

        <div class="nav-section-title">Services</div>

        <div class="services-section-wrapper">

          <div class="nav-section">
            <v-list-item
              :to="{ name: 'AppComparison', params: { appId: currentAppId }}"
              exact
              class="service-item rounded-t"
              @click="resetTopNav"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Product Comparison</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>mdi-compare-horizontal</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>

            <v-treeview
              activatable
              dense
              hoverable
              :items="comparableTaxonNavItems"
              :active="activeComparisonCategoryNav"
              :open="openComparisonNavItems"
              @update:active="comparisonCategoryNavChanged"
              class="child-nav-item tree-nav"
              v-if="routeVisibleIn('AppComparison')"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.icon && item.type !== 'taxon' && item.type !== 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:append="{ item }">
                <v-icon class="taxon-status-icon" :class="item.class" v-if="item.type === 'taxon'">
                  {{ item.icon }}
                </v-icon>
                <v-icon x-small class="pa-0 ml-0 mr-5" v-else-if="item.type === 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
            </v-treeview>

          </div>

          <div v-if="currentUserIsOwner || currentUserIsSysAdmin">
            <v-list-item
              :to="{ name: 'AppComparisonAnalytics', params: { appId: currentAppId }}"
              exact
              class="child-nav-item"
              v-if="routeVisibleIn('AppComparison')"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title class="ml-4">Comparison Analytics</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>mdi-poll</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>
          </div>

          <!-- <v-list-item
              class="child-nav-item"
              @click="resetTopNav"
              v-if="routeVisibleIn('AppComparison')"
              disabled
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title class="ml-4">Customer Requests</v-list-item-title>
                  <v-list-item-subtitle class="ml-4">(coming soon)</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="grey">mdi-face-agent</v-icon>
                </v-list-item-icon>
                
              </div>
          </v-list-item> -->

          <div class="nav-section">
            <v-list-item
              :to="{ name: 'AppFiltering', params: { appId: currentAppId }}"
              exact
              class="service-item"
              @click="resetTopNav"
              :disabled="currentApp && !currentApp.canUseFiltering"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Product Filtering</v-list-item-title>
                  <v-list-item-subtitle v-if="currentApp && !currentApp.canUseFiltering">(coming soon)</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon :color="currentApp && !currentApp.canUseFiltering ? 'grey': ''">mdi-filter-cog</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>

            <v-treeview
              activatable
              dense
              hoverable
              :items="filterableTaxonNavItems"
              :active="activeFilteringCategoryNav"
              :open="openFilteringNavItems"
              @update:active="filteringCategoryNavChanged"
              class="child-nav-item tree-nav"
              v-if="routeVisibleIn('AppFiltering')"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.icon && item.type !== 'taxon' && item.type !== 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
              <template v-slot:append="{ item }">
                <v-icon class="taxon-status-icon" :class="item.class" v-if="item.type === 'taxon'">
                  {{ item.icon }}
                </v-icon>
                <v-icon x-small class="pa-0 ml-0 mr-5" v-else-if="item.type === 'itemGroup'">
                  {{ item.icon }}
                </v-icon>
              </template>
            </v-treeview>
          </div>

          <!-- <div v-if="currentUserIsOwner">
            <v-list-item
              :to="{ name: 'AppFilteringAnalytics', params: { appId: currentAppId }}"
              exact
              class="child-nav-item"
              v-if="routeVisibleIn('AppFiltering')"
              disabled
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title class="ml-4">Filtering Analytics</v-list-item-title>
                  <v-list-item-subtitle class="ml-4">(coming soon)</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="grey">mdi-poll</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>
          </div> -->

          <div v-if="currentApp && currentApp.canUseSearch === true">
            <v-list-item
              :to="{ name: 'AppSearch', params: { appId: currentAppId }}"
              exact
              class="service-item"
              @click="resetTopNav"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Catalog Search</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>

            <!-- <v-list-item
              :to="{ name: 'AppLeadsAnalytics', params: { appId: currentAppId }}"
              exact
              class="child-nav-item"
              @click="resetTopNav"
              v-if="routeVisibleIn('AppLeads')"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                
                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title class="ml-4">Lead Analytics</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-poll</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item> -->
            
          </div>

          <div v-if="currentApp && currentApp.canUseAssistant === true">
            <v-list-item
                :to="{ name: 'AppAssistant', params: { appId: currentAppId }}"
                class="service-item"
                @click="resetTopNav"
                :disabled="currentApp && !currentApp.canUseAssistant"
              >
                <div class="d-flex align-center justify-space-between" style="width: 100%;">

                  <v-list-item-content v-if="!miniMenu">
                    <v-list-item-title>AI Assistant</v-list-item-title>
                    <!-- <v-list-item-subtitle>Catalog aware AI assistant</v-list-item-subtitle> -->
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon :color="currentApp && !currentApp.canUseAssistant ? 'grey': ''">mdi-creation</v-icon>
                  </v-list-item-icon>
                  
                </div>
            </v-list-item>
          </div>

          <div v-if="currentApp && currentApp.canUseInsights === true">
            <v-list-item
              :to="{ name: 'AppMarketInsights', params: { appId: currentAppId }}"
              class="service-item"
              @click="resetTopNav"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Market Insights</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chart-timeline-variant</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>
          </div>

          <div v-if="currentApp && currentApp.canUseLeads === true">
            <v-list-item
              :to="{ name: 'AppLeads', params: { appId: currentAppId }}"
              exact
              class="service-item"
              @click="resetTopNav"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Customer Leads</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-cursor-default-click</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>

            <v-list-item
              :to="{ name: 'AppLeadsAnalytics', params: { appId: currentAppId }}"
              exact
              class="child-nav-item"
              @click="resetTopNav"
              v-if="routeVisibleIn('AppLeads')"
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                
                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title class="ml-4">Lead Analytics</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-poll</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>
            
          </div>

          <div v-if="currentApp">
            <v-list-item
              exact
              class="service-item"
              disabled
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>A/B Testing</v-list-item-title>
                  <v-list-item-subtitle>coming soon</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon disabled>mdi-test-tube</v-icon>
                </v-list-item-icon>
                
              </div>
            </v-list-item>
          </div>

          <!-- <v-list-item
              class="service-item"
              @click="resetTopNav"
              disabled
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Agent Chat</v-list-item-title>
                  <v-list-item-subtitle>Compare board live chats</v-list-item-subtitle>
                  <v-list-item-subtitle>(coming soon)</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="grey">mdi-face-agent</v-icon>
                </v-list-item-icon>
                
              </div>
          </v-list-item> -->

          <!-- <v-list-item
              class="service-item"
              @click="resetTopNav"
              disabled
            >
              <div class="d-flex align-center justify-space-between" style="width: 100%;">

                <v-list-item-content v-if="!miniMenu">
                  <v-list-item-title>Web Hooks</v-list-item-title>
                  <v-list-item-subtitle>(coming soon)</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="grey">mdi-webhook</v-icon>
                </v-list-item-icon>
                
              </div>
          </v-list-item> -->

        </div>

        <div>
          <v-list-item
            :to="{ name: 'AppIntegration', params: { appId: currentAppId }}"
            exact
            class="top-level-item"
            @click="resetTopNav"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title>Integrations</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-connection</v-icon>
              </v-list-item-icon>
            </div>
          </v-list-item>
        </div>
        <div v-if="currentUserIsOwner">
          <v-list-item
            :to="{ name: 'AppSettings', params: { appId: currentAppId }}"
            exact
            @click="resetTopNav"
            class="top-level-item"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title>App Settings</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
            </div>
          </v-list-item>

          <v-list-item
            :to="{ name: 'AppBilling', params: { appId: currentAppId }}"
            exact
            class="top-level-item child"
            @click="resetTopNav"
            v-if="routeVisibleIn('AppSettings')"
          >
            <div class="d-flex align-center justify-space-between" style="width: 100%;">

              <v-list-item-content v-if="!miniMenu">
                <v-list-item-title class="ml-4">Billing</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              
            </div>
          </v-list-item>
        </div>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Veux types
import * as DeveloperAppTypes from '../../store/types/apps'

import AuthUtil from '../../authUtil'

import { mapState } from 'vuex'


import pikleServiceStatusMixin from '@/mixins/ServiceStatusMixin.js'


export default {
  name: 'CurrentAppNav',
  mixins: [pikleServiceStatusMixin],
  props: {
  },
  data () {
    return {
      activeCategoryNav: [],
      activeComparisonCategoryNav: [],
      activeFilteringCategoryNav: [],
      openNavItems: [],
      openComparisonNavItems: [],
      openFilteringNavItems: [],
      miniMenu: false,
      routeHierarchy: {
        // AppPikleCategories: ['AppPikleCategoryEditor', 'AppTaxonOverview', 'AppPikleCategoryEditor', 'AppTaxonCatalogGrouped', 'AppTaxonFeatures', 'AppTaxonContentSettings'],
        // AppPikleCategories: ['AppTaxonOverview'],
        AppComparison: ['AppComparisonTaxonOverview', 'AppComparisonTaxonCatalog', 'AppComparisonTaxonCatalogGrouped', 'AppComparisonTaxonFeatures', 'AppComparisonAnalytics'],
        AppFiltering: ['AppFilteringTaxonOverview', 'AppFilteringTaxonCatalog', 'AppFilteringTaxonCatalogGrouped', 'AppFilteringTaxonFeatures', 'AppFilteringAnalytics'],
        AppLeads: ['AppLeadsAnalytics'],
        AppSettings: ['AppBilling']
      }
    }
  },
  computed: {
    ...mapState({
      userMetaData: state => state.user.metaData,
      currentApp: state => state.apps.currentApp,
      allServiceModes: state => state.apps.serviceModes,
      appCurrentTaxon: state => state.apps.currentTaxon,
      currentTaxonGroup: state => state.apps.currentTaxonGroup,
      currentTaxonGroupsMap: state => state.apps.currentTaxonGroups,
      currentAppNavExpansions: state => state.apps.currentAppNavExpansions
    }),
    currentRouteName () {
        return this.$route.name;
    },
    currentAppId () {
      return this.currentApp ? this.currentApp.id : null
    },
    currentUserID () {
      // return this.userMetaData ? this.userMetaData.uid : null
      return AuthUtil.auth.currentUser ? AuthUtil.auth.currentUser.uid : null;
    },
    currentUserIsOwner () {
      return this.currentApp ? this.currentApp.owner === this.currentUserID : false
    },
    currentUserIsSysAdmin () {
      return this.userMetaData ? this.userMetaData.isSysAdmin : false;
    },
    taxoNavItemsMap () {
      const items = {}

      this.taxonList.forEach((item) => {
        const taxonItem = {
          id: item.id,
          name: item.name,
          type: 'taxon',
          icon: 'mdi-circle-small',
        }

        // Current Taxon groups
        // if(this.currentTaxon && this.currentTaxon.id === item.id) {
        //   items[`${item.id}-products`] = {id: `${item.id}-products`, name: `Catalog`, routeName: 'AppPikleCategoryEditor', children: [], icon: 'mdi-format-list-bulleted-type'}
        //   items[`${item.id}-features`] = {id: `${item.id}-features`, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppTaxonFeatures'}
        //   // items[`${item.id}-testing`] = {id: `${item.id}-testing`, name: 'Test & Train', icon: 'mdi-test-tube', routeName: 'AppTaxonTraining'}
        //   items[`${item.id}-content`] = {id: `${item.id}-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}

        //   // items['products'] = {id: 'products', name: 'Catalog', routeName: 'AppTaxonEditor', children: [], icon: 'mdi-format-list-bulleted-type'}
        //   // items['features'] = {id: 'features', name: 'Features', icon: 'mdi-format-list-bulleted', routeName: 'AppTaxonFeatures'}
        //   // items['testing'] = {id: 'testing', name: 'Test & Train', icon: 'mdi-test-tube', routeName: 'AppTaxonTraining'}
        //   // items['content'] = {id: 'content', name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}

        //   this.currentTaxonGroups.forEach((group) => {
        //     const newGroupItem = {
        //       id: group.id,
        //       name: group.name,
        //       type: 'itemGroup'
        //     }
        //     items[group.id] = newGroupItem
        //   });

        // }

        items[item.id] = taxonItem
      });

      return items
    },
    currentTaxonNavItems () {
      const items = []
      if (this.currentTaxon) {
        const taxonItem = {
          id: this.currentTaxon.id,
          name: this.currentTaxon.name,
          type: 'taxon',
          children: []
        }
        const itemServiceMode = this.getTaxonServiceMode(this.currentTaxon.id)
        if (itemServiceMode) {
          taxonItem.class = itemServiceMode.id
        }

        // Current Taxon groups
        // taxonItem.children.push(...[
        //   {id: `${this.currentTaxon.id}-products`, name: 'Catalog', routeName: 'AppPikleCategoryEditor', children: [], icon: 'mdi-format-list-bulleted-type'},
        //   {id: `${this.currentTaxon.id}-features`, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppTaxonFeatures'},
        //   {id: `${this.currentTaxon.id}-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}
        // ])
        // this.currentTaxonGroups.forEach((group) => {
        //   const newGroupItem = {
        //     id: group.id,
        //     name: group.name,
        //     type: 'itemGroup'
        //   }
        //   if (group.ruleBased) {
        //     newGroupItem.icon = 'mdi-auto-fix'
        //   }
        //   taxonItem.children[0].children.push(newGroupItem)
        // });

        // if (taxonItem.children[0] && taxonItem.children[0].children) {
        //   taxonItem.children[0].children.sort((a, b) => {
        //     if(a.name < b.name) { return -1; }
        //     if(a.name > b.name) { return 1; }
        //     return 0;
        //   })
        // }


        items.push(taxonItem)
      }

      return items
    },
    // categoryNavItems () {
    //   const items = []
    //   this.taxonList.forEach((item) => {
    //     const taxonItem = {
    //       id: item.id,
    //       name: item.name,
    //       type: 'taxon',
    //       children: [],
    //       icon: 'mdi-circle-small'
    //     }
    //     const itemServiceMode = this.getTaxonServiceMode(item.id)
    //     if (itemServiceMode) {
    //       taxonItem.class = itemServiceMode.id
    //     }

    //     // Current Taxon groups
    //     if(this.currentTaxon && this.currentTaxon.id === item.id) {
    //       taxonItem.children = [
    //         {id: `${item.id}-products`, name: 'Comparable Items', routeName: 'AppTaxonEditor', children: [], icon: 'mdi-format-list-bulleted-type'},
    //         {id: `${item.id}-features`, name: 'Features', icon: 'mdi-format-list-bulleted', routeName: 'AppTaxonFeatures'},
    //         // {id: `${item.id}-testing`, name: 'Test & Train', icon: 'mdi-test-tube', routeName: 'AppTaxonTraining'},
    //         {id: `${item.id}-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}
    //         // {id: 'pikle-comparison', name: 'Comparison', icon: 'mdi-cog-transfer', routeName: 'AppTaxonComparison', children: [
    //         //   {id: 'pikle-comparison-training', name: 'Training', icon: 'mdi-brain', routeName: 'AppTaxonComparison'},
    //         //   {id: 'pikle-comparison-content', name: 'Refresh Settings', icon: 'mdi-web-sync', routeName: 'AppTaxonComparison'},
    //         //   {id: 'pikle-comparison-approve', name: 'Review/Approve', icon: 'mdi-check', routeName: 'AppTaxonComparison'}
    //         // ]},
    //       ]
    //       this.currentTaxonGroups.forEach((group) => {
    //         const newGroupItem = {
    //           id: group.id,
    //           name: group.name,
    //           type: 'itemGroup'
    //         }
    //         taxonItem.children[0].children.push(newGroupItem)
    //       });

    //       if (taxonItem.children[0] && taxonItem.children[0].children) {
    //         taxonItem.children[0].children.sort((a, b) => {
    //           if(a.name < b.name) { return -1; }
    //           if(a.name > b.name) { return 1; }
    //           return 0;
    //         })
    //       }

    //     }

    //     items.push(taxonItem)
    //   });

    //   return items
    // },
    comparableTaxonItemsMap () {
      const items = {}

      this.taxonList.forEach((item) => {
        const taxonItem = {
          id: item.id,
          taxonId: item.id,
          name: item.name,
          type: 'taxon',
          icon: 'mdi-circle-small',
        }
        const itemServiceMode = this.getTaxonServiceMode(this.currentApp, this.allServiceModes, item.id, 'comparison')
        if (itemServiceMode) {
          taxonItem.class = itemServiceMode.id
        }

        // Current Taxon groups
        // if(this.currentTaxon && this.currentTaxon.id === item.id) {
        //   items[`${item.id}-products`] = {id: `${item.id}-products`, taxonId: item.id, name: `Catalog`, routeName: 'AppComparisonTaxonCatalog', children: [], icon: 'mdi-format-list-bulleted-type'}
        //   items[`${item.id}-features`] = {id: `${item.id}-features`, taxonId: item.id, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppComparisonTaxonFeatures'}
        //   // items[`${item.id}-testing`] = {id: `${item.id}-testing`, name: 'Test & Train', icon: 'mdi-test-tube', routeName: 'AppTaxonTraining'}
        //   // items[`${item.id}-content`] = {id: `${item.id}-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}

        //   this.currentTaxonGroups.forEach((group) => {
        //     const newGroupItem = {
        //       id: `${group.id}`,
        //       groupId: group.id,
        //       name: group.name,
        //       type: 'itemGroup'
        //     }
        //     items[`${group.id}`] = newGroupItem
        //   });

        // }

        items[`${item.id}`] = taxonItem
      });

      return items
    },
    comparableTaxonNavItems () {
      const items = []
      if (this.currentTaxon) {
        // this.taxonList.forEach((item) => {
        const item = this.currentTaxon
        const taxonItem = {
          id: `${item.id}`,
          name: item.name,
          type: 'taxon',
          children: [],
          icon: 'mdi-circle-small'
        }
        const itemServiceMode = this.getTaxonServiceMode(this.currentApp, this.allServiceModes, item.id, 'comparison')
        if (itemServiceMode) {
          taxonItem.class = itemServiceMode.id
        }

          // Current Taxon groups
          // if(this.currentTaxon && this.currentTaxon.id === item.id) {
          //   taxonItem.children = [
          //     {id: `${item.id}-products`, name: 'Catalog', routeName: 'AppComparisonTaxonCatalog', children: [], icon: 'mdi-format-list-bulleted-type'},
          //     {id: `${item.id}-features`, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppComparisonTaxonFeatures'},
          //     // {id: `${item.id}-comparison-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}
          //   ]
          //   this.currentTaxonGroups.forEach((group) => {
          //     const newGroupItem = {
          //       id: `${group.id}`,
          //       groupId: group.id,
          //       name: group.name,
          //       type: 'itemGroup'
          //     }
          //     if (group.ruleBased) {
          //       newGroupItem.icon = 'mdi-auto-fix'
          //     }
          //     taxonItem.children[0].children.push(newGroupItem)
          //   });

          //   if (taxonItem.children[0] && taxonItem.children[0].children) {
          //     taxonItem.children[0].children.sort((a, b) => {
          //       if(a.name < b.name) { return -1; }
          //       if(a.name > b.name) { return 1; }
          //       return 0;
          //     })
          //   }

          // }

        items.push(taxonItem)
      }
      return items
    },
    filterableTaxonItemsMap () {
      const items = {}

      this.taxonList.forEach((item) => {
        const taxonItem = {
          id: `${item.id}`,
          taxonId: item.id,
          name: item.name,
          type: 'taxon',
          icon: 'mdi-circle-small',
        }
        const itemServiceMode = this.getTaxonServiceMode(this.currentApp, this.allServiceModes, item.id, 'filtering')
        if (itemServiceMode) {
          taxonItem.class = itemServiceMode.id
        }

        // Current Taxon groups
        // if(this.currentTaxon && this.currentTaxon.id === item.id) {
        //   items[`${item.id}-products`] = {id: `${item.id}-products`, taxonId: item.id, name: `Catalog`, routeName: 'AppFilteringTaxonCatalog', children: [], icon: 'mdi-format-list-bulleted-type'}
        //   items[`${item.id}-features`] = {id: `${item.id}-features`, taxonId: item.id, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppFilteringTaxonFeatures'}
        //   // items[`${item.id}-testing`] = {id: `${item.id}-testing`, name: 'Test & Train', icon: 'mdi-test-tube', routeName: 'AppTaxonTraining'}
        //   // items[`${item.id}-content`] = {id: `${item.id}-content`, name: 'Content Settings', icon: 'mdi-cog-transfer', routeName: 'AppTaxonContentSettings'}

        //   this.currentTaxonGroups.forEach((group) => {
        //     const newGroupItem = {
        //       id: `${group.id}`,
        //       groupId: group.id,
        //       name: group.name,
        //       type: 'itemGroup'
        //     }
        //     items[group.id] = newGroupItem
        //   });

        // }

        items[`${item.id}`] = taxonItem
      });

      return items
    },
    filterableTaxonNavItems () {
      const items = []
      if (this.currentTaxon) {
        // this.taxonList.forEach((item) => {
        const item = this.currentTaxon
        const taxonItem = {
          id: `${item.id}`,
          name: item.name,
          type: 'taxon',
          children: [],
          icon: 'mdi-circle-small'
        }
        const itemServiceMode = this.getTaxonServiceMode(this.currentApp, this.allServiceModes, item.id, 'filtering')
        if (itemServiceMode) {
          taxonItem.class = itemServiceMode.id
        }

          // Current Taxon groups
          // if(this.currentTaxon && this.currentTaxon.id === item.id) {
          //   taxonItem.children = [
          //     {id: `${item.id}-products`, name: 'Catalog', routeName: 'AppFilteringTaxonCatalog', children: [], icon: 'mdi-format-list-bulleted-type'},
          //     {id: `${item.id}-features`, name: 'Attributes', icon: 'mdi-table-column', routeName: 'AppFilteringTaxonFeatures'},
          //   ]
          //   this.currentTaxonGroups.forEach((group) => {
          //     const newGroupItem = {
          //       id: `${group.id}`,
          //       groupId: group.id,
          //       name: group.name,
          //       type: 'itemGroup',
          //     }
          //     if (group.ruleBased) {
          //       newGroupItem.icon = 'mdi-auto-fix'
          //     }
          //     taxonItem.children[0].children.push(newGroupItem)
          //   });

          //   if (taxonItem.children[0] && taxonItem.children[0].children) {
          //     taxonItem.children[0].children.sort((a, b) => {
          //       if(a.name < b.name) { return -1; }
          //       if(a.name > b.name) { return 1; }
          //       return 0;
          //     })
          //   }

          // }

        items.push(taxonItem)
        // });
      }
      return items
    },
    currentTaxon: {
      // getter
      get: function () {
        return this.appCurrentTaxon
      },
      // setter
      set: function (newValue) {
        this.$store.dispatch(`apps/${DeveloperAppTypes.ACTION_SET_CURRENT_APP_TAXON}`, newValue)
      }
    },
    selectedTaxonGroup: {
      // getter
      get: function () {
        return this.currentTaxonGroup
      },
      // setter
      set: function (newValue) {
        this.$store.dispatch(`apps/${DeveloperAppTypes.ACTION_SET_CURRENT_APP_TAXON_GROUP}`, newValue)
      }
    },
    currentTaxonGroups() {
      let res = []
      if (this.currentTaxonGroupsMap) {
        res = Object.entries(this.currentTaxonGroupsMap).map(([key, value]) => { return {id: key, ...value} })
      }
      return res
    },
    taxonMap () {
      return this.currentApp ? this.currentApp.taxons : null;
    },
    taxonList () {
      const res = []
      Object.keys(this.taxonMap).forEach((key) => {
        const taxonItem = {
          id: key,
          name: this.taxonMap[key].name,
          criteria: this.taxonMap[key].criteria || {},
          itemsCount: this.taxonMap[key].itemsCount || 0
        }
        res.push(taxonItem)
      })
      // console.log(`taxonList = ${JSON.stringify(res)}`)
      return res
    }
  },
  mounted: function () {
    // this.activeCategoryNav = []
    // if (this.currentRouteName === 'AppTaxonComparison') {
    //   this.activeCategoryNav = ['comparison']
    // }
    // this.openNavItems = []
    // this.updateActiveNavItems(this.currentRouteName)
    // const groupId = this.$route.params.groupId
    // if (groupId) {
    //   console.log(`setting selected taxon group`)
    //   this.selectedTaxonGroup = groupId
    // }
  },
  watch: {
    currentTaxon: async function (newValue, oldValue) {
      // console.log(`Current taxon changed = ${JSON.stringify(newValue)}`)
      if (newValue) {
        if (!oldValue || newValue.id !== oldValue.id) {
          await this.$store.dispatch(`apps/${DeveloperAppTypes.FETCH_CURRENT_TAXON_GROUPS}`)
          if (this.currentApp && this.currentApp.canUseFiltering) {
            this.$store.dispatch(`apps/${DeveloperAppTypes.FETCH_CURRENT_TAXON_FILTERS}`)
          }
        }
        this.updateActiveNavItems(this.currentRouteName)
      }
    },
    selectedTaxonGroup: function (newValue, oldValue) {
      if (newValue && this.currentTaxon && newValue !== oldValue) {

        if (this.$route.name.indexOf('AppComparison') == 0) {
          this.activeComparisonCategoryNav = [this.selectedTaxonGroup]
          this.openComparisonNavItems.push(`${this.currentTaxon.id}-products`)  
        } else if (this.$route.name.indexOf('AppFiltering') == 0) {
          this.activeFilteringCategoryNav = [this.selectedTaxonGroup]
          this.openFilteringNavItems.push(`${this.currentTaxon.id}-products`)
        } else {
          this.activeCategoryNav = [this.selectedTaxonGroup]
          this.openNavItems.push(`${this.currentTaxon.id}-products`)
        }
      }
    },
    currentTaxonGroupsMap: function (newValue, oldValue) {
        if (newValue && this.selectedTaxonGroup && newValue !== oldValue) {

          if (this.$route.name.indexOf('AppComparison') == 0) {
            this.activeComparisonCategoryNav = [this.selectedTaxonGroup]
            this.openComparisonNavItems.push(`${this.currentTaxon.id}-products`)  
          } else if (this.$route.name.indexOf('AppFiltering') == 0) {
            this.activeFilteringCategoryNav = [this.selectedTaxonGroup]
            this.openFilteringNavItems.push(`${this.currentTaxon.id}-products`)
          } else {
            this.activeCategoryNav = [this.selectedTaxonGroup]
            this.openNavItems.push(`${this.currentTaxon.id}-products`)
          }
          // this.openNavItems.push(`${this.currentTaxon.id}-products`)
          // openComparisonNavItems
          // this.activeCategoryNav = [this.selectedTaxonGroup]
        
        }
    },
    currentRouteName: function (newValue) {
      this.updateActiveNavItems(newValue)
    }
  },
  methods: {
    updateActiveNavItems: function (routeName) {
      // Set root item expansion state
      if (this.routeHierarchy[routeName]) {
        // Is a root item so set the expansion state in the store
        this.$store.commit(`apps/${DeveloperAppTypes.MUTATION_SET_NAV_ITEM_EXPANDED}`, routeName)
      }
      if (this.currentTaxon) {
        
        if (this.$route.name.indexOf('AppComparison') == 0) {
          this.openComparisonNavItems = [this.currentTaxon.id]
          this.openFilteringNavItems = []
          this.openNavItems = []
          this.activeCategoryNav = []
          this.activeFilteringCategoryNav = []
        } else if (this.$route.name.indexOf('AppFiltering') == 0) {
          this.openFilteringNavItems = [this.currentTaxon.id]
          this.openComparisonNavItems = []
          this.openNavItems = []
          this.activeCategoryNav = []
          this.activeComparisonCategoryNav = []
        } else {
          this.openFilteringNavItems = []
          this.openComparisonNavItems = []
          this.openNavItems = [this.currentTaxon.id]
          this.activeComparisonCategoryNav = []
          this.activeFilteringCategoryNav = []
        }
        // this.openNavItems = []

        if (routeName) {
          if (this.selectedTaxonGroup) {
            if (this.$route.name.indexOf('AppComparison') == 0) {
              this.activeComparisonCategoryNav = [this.selectedTaxonGroup]
              this.openComparisonNavItems.push(`${this.currentTaxon.id}-products`)  
            } else if (this.$route.name.indexOf('AppFiltering') == 0) {
              this.activeFilteringCategoryNav = [this.selectedTaxonGroup]
              this.openFilteringNavItems.push(`${this.currentTaxon.id}-products`)
            } else {
              this.activeCategoryNav = [this.selectedTaxonGroup]
              this.openNavItems.push(`${this.currentTaxon.id}-products`)
            }

              // this.openNavItems.push(`${this.currentTaxon.id}-products`)
              // this.activeCategoryNav = [this.selectedTaxonGroup]

          }
          else if (routeName === 'AppTaxonOverview') {
            this.activeCategoryNav = [this.currentTaxon.id]
          }
          // else if (routeName === 'AppPikleCategoryEditor') {
          //   this.openNavItems.push(`${this.currentTaxon.id}`)
          //   this.activeCategoryNav = [`${this.currentTaxon.id}-products`]
          // }
          else if (routeName === 'AppTaxonComparison') {
            this.activeCategoryNav = ['comparison']
          }
          else if (routeName === 'AppComparisonTaxonOverview') {
            this.activeComparisonCategoryNav = [`${this.currentTaxon.id}`]
          }
          else if (routeName === 'AppTaxonFeatures') {
            this.activeCategoryNav = [`${this.currentTaxon.id}-features`]
          }
          else if (routeName === 'AppTaxonContentSettings') {
            this.activeCategoryNav = [`${this.currentTaxon.id}-content`]
          }
          else if (routeName === 'AppTaxonTraining') {
            this.activeCategoryNav = [`${this.currentTaxon.id}-testing`]
          }
          
          else if (routeName === 'AppComparisonTaxonCatalog') {
            this.openComparisonNavItems.push(`${this.currentTaxon.id}`)
            this.activeComparisonCategoryNav = [`${this.currentTaxon.id}-products`]
          }
          else if (routeName === 'AppComparisonTaxonFeatures') {
            this.openComparisonNavItems.push(`${this.currentTaxon.id}`)
            this.activeComparisonCategoryNav = [`${this.currentTaxon.id}-features`]
          }

          else if (routeName === 'AppFilteringTaxonOverview') {
            this.activeFilteringCategoryNav = [`${this.currentTaxon.id}`]
          }
          else if (routeName === 'AppFilteringTaxonCatalog') {
            this.openFilteringNavItems.push(`${this.currentTaxon.id}`)
            this.activeFilteringCategoryNav = [`${this.currentTaxon.id}-products`]
          }
          else if (routeName === 'AppFilteringTaxonFeatures') {
            this.openFilteringNavItems.push(`${this.currentTaxon.id}`)
            this.activeFilteringCategoryNav = [`${this.currentTaxon.id}-features`]
          }

          else if (routeName === 'AppTaxonItemEditor') {
            this.openNavItems.push(`${this.currentTaxon.id}-products`)
            if (this.selectedTaxonGroup) {
              this.activeCategoryNav = [this.selectedTaxonGroup]
            }
            // else {
            //   this.activeCategoryNav = ['products']
            // }
          }
          else if (routeName === 'AppComparisonAnalytics') {
            this.activeCategoryNav = []
            this.activeComparisonCategoryNav = []
            this.activeFilteringCategoryNav = []
          }

        }
      }
    },
    comparisonCategoryNavChanged: function (newActiveIdsArray) {
      const newActiveArray = []
      newActiveIdsArray.forEach((itemId) => {
        const navItem = this.comparableTaxonItemsMap[itemId]
        if (navItem) {
          newActiveArray.push(navItem)
        }
      });

      if (newActiveArray && newActiveArray.length > 0 && this.taxonList) {
        const firstActive = newActiveArray[0]
        
        const taxonItem = this.taxonList.find(item => item.id === firstActive.taxonId) || this.currentTaxon
        if (firstActive.type === 'taxon' && taxonItem && this.$route.name !== 'AppComparisonTaxonOverview') {
          this.selectedTaxonGroup = null
          if (!this.currentTaxon || this.currentTaxon.id !== taxonItem.id) {
            this.currentTaxon = taxonItem;
          }
          this.$router.push({ name: 'AppComparisonTaxonOverview', params: { appId: this.currentApp.id, taxonId: taxonItem.id } })
        }
        else if (firstActive.type === 'itemGroup' && this.currentTaxonGroupsMap) {
          if (this.selectedTaxonGroup !==  firstActive.groupId || this.currentRouteName !== 'AppComparisonTaxonCatalogGrouped') {
            this.selectedTaxonGroup = firstActive.groupId
            this.$router.push({ name: 'AppComparisonTaxonCatalogGrouped', params: { appId: this.currentApp.id, taxonId: this.currentTaxon.id, groupId: this.selectedTaxonGroup } })
          }
        } 
        else if (firstActive.routeName && taxonItem) {
          this.selectedTaxonGroup = null
          if (firstActive.routeName !== this.currentRouteName) {
            const routeChange = { name: firstActive.routeName, params: { appId: this.currentApp.id, taxonId: taxonItem.id } }
            if (firstActive.query) {
              routeChange.query = firstActive.query
            }
            this.$router.push(routeChange)
          }
        }
      }
    },
    filteringCategoryNavChanged: function (newActiveIdsArray) {
      const newActiveArray = []
      
      newActiveIdsArray.forEach((itemId) => {
        const navItem = this.filterableTaxonItemsMap[itemId]
        if (navItem) {
          newActiveArray.push(navItem)
        }
      });

      if (newActiveArray && newActiveArray.length > 0 && this.taxonList) {
        const firstActive = newActiveArray[0]
        
        const taxonItem = this.taxonList.find(item => item.id === firstActive.taxonId) || this.currentTaxon
        
        if (firstActive.type === 'taxon' && taxonItem && this.$route.name !== 'AppFilteringTaxonOverview') {
          this.selectedTaxonGroup = null
          if (!this.currentTaxon || this.currentTaxon.id !== taxonItem.id) {
            this.currentTaxon = taxonItem;
          }
          this.$router.push({ name: 'AppFilteringTaxonOverview', params: { appId: this.currentApp.id, taxonId: taxonItem.id } })
        }
        else if (firstActive.type === 'itemGroup' && this.currentTaxonGroupsMap) {
          if (this.selectedTaxonGroup !==  firstActive.groupId || this.currentRouteName !== 'AppFilteringTaxonCatalogGrouped') {
            this.selectedTaxonGroup = firstActive.groupId
            this.$router.push({ name: 'AppFilteringTaxonCatalogGrouped', params: { appId: this.currentApp.id, taxonId: this.currentTaxon.id, groupId: this.selectedTaxonGroup } })
          }
        } 
        else if (firstActive.routeName && taxonItem) {
          
          this.selectedTaxonGroup = null
          if (firstActive.routeName !== this.currentRouteName) {
            
            const routeChange = { name: firstActive.routeName, params: { appId: this.currentApp.id, taxonId: taxonItem.id } }
            if (firstActive.query) {
              routeChange.query = firstActive.query
            }
            this.$router.push(routeChange)
          }
        }
      }
    },
    categoryNavChanged: function (newActiveIdsArray) {
      const newActiveArray = []
      newActiveIdsArray.forEach((itemId) => {
        const navItem = this.taxoNavItemsMap[itemId]
        if (navItem) {
          newActiveArray.push(navItem)
        }
      });

      if (newActiveArray && newActiveArray.length > 0) {
        const firstActive = newActiveArray[0]
        // console.log(`firstActive = ${JSON.stringify(firstActive)}`)
        if (firstActive.type === 'taxon' && this.taxonList) {
          const taxonItem = this.taxonList.find(item => item.id === firstActive.id)
          // console.log(`Should nav to ${JSON.stringify(taxonItem)}`)
          this.navigateToTaxon(taxonItem)
        } else if (firstActive.type === 'itemGroup' && this.currentTaxonGroupsMap) {
          if (this.selectedTaxonGroup !==  firstActive.id || this.currentRouteName !== 'AppTaxonCatalogGrouped') {
            this.selectedTaxonGroup = firstActive.id
            this.$router.push({ name: 'AppTaxonCatalogGrouped', params: { appId: this.currentApp.id, taxonId: this.currentTaxon.id, groupId: this.selectedTaxonGroup } })
          }
        } else if (firstActive.routeName && this.currentTaxon) {
          this.selectedTaxonGroup = null
          if (firstActive.routeName !== this.currentRouteName) {
            const routeChange = { name: firstActive.routeName, params: { appId: this.currentApp.id, taxonId: this.currentTaxon.id } }
            if (firstActive.query) {
              routeChange.query = firstActive.query
            }
            this.$router.push(routeChange)
          }
        }
      }
    },
    resetTopNav: function () {
      this.activeCategoryNav = []
      this.activeComparisonCategoryNav = []
      this.activeFilteringCategoryNav = []
      this.selectedTaxonGroup = null
      this.currentTaxon = null
    },
    navigateToTaxon: function (taxon) {
      if (!taxon) return;
      this.selectedTaxonGroup = null
      if (!this.currentTaxon || this.currentTaxon.id !== taxon.id) {
        this.currentTaxon = taxon;
      }
      if (this.$route.name !== 'AppTaxonOverview') {
        // console.log(`navigateToTaxon params = ${JSON.stringify({ appId: this.currentApp.id, taxonId: taxon.id })}`)
        this.$router.push({ name: 'AppTaxonOverview', params: { appId: this.currentApp.id, taxonId: taxon.id } })
      }
    },
    routeVisibleIn: function (rootRoute) {
      // Return true if the current route is the given rootRoute, or it was previousely expanded
      if (this.currentRouteName === rootRoute || this.currentAppNavExpansions[rootRoute]) {
        return true;
      } else {
        // Check if current route is a sibling of the item (has the same rootRoute)
        return this.routeHierarchy[rootRoute] && this.routeHierarchy[rootRoute] ? this.routeHierarchy[rootRoute].includes(this.currentRouteName) : false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.nav-section {
  .v-treeview-node__root {
    
    padding-left: 18px !important;
    cursor: pointer;

    .v-treeview-node__level {
      width: 8px;
    }
  }

  .v-treeview-node--leaf {
    cursor: pointer;
  }
}
</style>

<style scoped lang="scss">

.taxon-status-icon {

  margin-right: 10px;
  
  &.disabled {
    color: #f44336;
  }
  &.testing {
    color: #c44bc4;
  }
  &.enabled {
    color: #4caf50;
  }
}

.app-page-list {

  .nav-section-title {
    margin: 20px 15px 5px 23px;
    color: #bc9797;
    font-size: 0.9rem;
  }

  .services-section-wrapper {
    margin: 0px 8px 30px 8px;
    border-radius: 5px;
    overflow: hidden;
  }

  .service-item {
    background-color: rgb(24 0 44 / 70%) !important;
    margin-top: 1px;
    // border-top: 1px solid rgb(127 51 148);
    border-top:  1px solid rgb(70 53 75);
    padding-top: 5px;
    padding-bottom: 5px;

    &.outer {
      padding-left: 20px;
      padding-right: 25px;
    }
  }

  .top-level-item {
    border-top: 1px solid rgb(127 51 148);
    
    background-color: rgb(24 0 44 / 10%) !important;

    margin-top: 0px;
    padding-left: 20px;
    padding-right: 25px;

    &.last-item {
      border-bottom: 1px solid rgb(127 51 148);
    }

    &.child {
      border-top: none;
    }
  }

  .child-nav-item {
    // background-color: rgb(218 213 213 / 72%) !important;
    background-color: rgb(24 0 44 / 57%) !important;
    // padding-left: 16px;

    &.outer {
      padding-left: 20px;
      padding-right: 25px;
    }

    &.top-level-child {
      // background-color: rgb(49 5 84 / 57%) !important;
      background-color: rgb(24 0 44 / 30%) !important;
    }
  }

  .tree-nav {
    border-top: 1px solid #333;
  }

  .current-taxon-wrapper {

    .tree-nav {
      border-top: none;
    }

    // border-bottom: 1px solid rgb(127 51 148);
    // border-bottom: 1px solid rgb(38 16 45);
    border-bottom: 1px solid rgb(66 13 82);
    // rgb(81 32 96)
  }

  .v-list-item__title {
    font-size: 1rem !important;
    line-height: 1.1rem !important;
  }

  .v-list-item__icon {
    // margin-right: 15px !important;
    padding: 0px !important;
    margin: 0px 5px 0px 0px !important;
  }

  .v-list-item__content {
    padding: 0px !important;
  }

  .v-list-item-group .v-list-item--active {
    background-color: #212121;
  }

  .v-list-item--active:before, .v-list-item--active:hover:before, .v-list-item:focus:before {
    opacity: 0;
  }

  .nav-header-item {
    .v-list-item__icon {
    margin: 8px 5px 0px 0px !important;
  }
  }
}

</style>
