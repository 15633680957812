import Vue from 'vue'
import Vuex from 'vuex'
import apps from './modules/apps'
import training from './modules/training'
import user from './modules/user'

// Use Vuex
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    apps,
    training
  },
  strict: debug
})
