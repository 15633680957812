<template>
  <v-app>
    <v-main>
      <CurrentAppNav v-if="currentApp" />
      <div v-else-if="!fetchingApp && !currentRouteHasNoNav">
        <AppBar v-if="currentRouteRequiresAuth" />
        <AppBarPublic v-else />
      </div>
      <v-container fluid class="router-view-wrapper">
        <v-row
          no-gutters
          class="fill-height"
        >
          <v-col
           class="fill-height"
          >
            <v-container v-if="currentApp" class="d-flex justify-space-between pt-4 pb-0">
              <div>
                <h4 class="secondary--text ml-3">{{currentApp.name}}</h4>
                <div class="text-caption info--text ml-3">{{currentApp.appDomain}}</div>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mr-6" icon :to="{ name: 'Documentation', params: { appId: currentApp.id }}" target="_blank"><v-icon>mdi-help-circle</v-icon></v-btn>
                </template>
                <span>Documentation and help</span>
              </v-tooltip>
              <!-- <v-btn class="mr-6" icon :to="`/apps/${this.$route.params.appId}/docs`"><v-icon>mdi-help-circle</v-icon></v-btn> -->
            </v-container>
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import AppBar from '@/components/AppBar'
import AppBarPublic from '@/components/AppBarPublic'
import CurrentAppNav from '@/components/Navigation/CurrentAppNav'

import apiIntercepters from './api/interceptors'

export default {
  name: 'App',
  components:  {
    AppBar,
    AppBarPublic,
    CurrentAppNav
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      currentApp: state => state.apps.currentApp,
      fetchingApp: state => state.apps.fetchingAppStatus,
      inited: state => state.apps.inited,
    }),
    currentRouteName() {
        return this.$route.name;
    },
    currentRouteRequiresAuth() {
        return this.$route.meta && this.$route.meta.requiresAuth;
    },
    currentRouteHasNoNav() {
        return this.$route.meta && this.$route.meta.noNav;
    }
  },
  created: function () {
    apiIntercepters.init()
  }
};
</script>
<style lang="sass">
  $color-pack: false
  @import '~vuetify/src/styles/main.sass'
</style>
<style lang="scss">
  @import './styles/app.scss';
</style>
