<template>
  <!-- <div class="app-top-bar"> -->
  <v-app-bar
    dense
    absolute
    class="public-app-bar"
    :class="barColor"
  >
    <div class="app-top-bar mx-0 d-flex align-end">
      <router-link to="/">
        <v-img
          alt="Pikle Logo"
          :src="require('../assets/logo-full-light.svg')"
          max-width="75"
        ></v-img>
      </router-link>
    </div>
    <!-- <v-spacer></v-spacer> -->
    <!-- <v-menu
      v-if="$vuetify.breakpoint.smAndDown && !inStaging"
      close-on-click
      rounded="0"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon
          dark
          v-bind="attrs"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>

      <v-list>
        <v-list-item @click="$router.push('/comparison').catch(err => {})">
          <v-list-item-title class="ma-0">
            <v-icon small class="mr-2">mdi-compare-horizontal</v-icon>
            Product Comparison
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$router.push('/filtering').catch(err => {})">
          <v-list-item-title class="ma-0">
            <v-icon small class="mr-2">mdi-filter-cog</v-icon>
            Product Filtering
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
    <!-- <div class="services-menu-section d-none d-md-flex" v-if="!inStaging">
      <v-btn tile x-large dark text to="/comparison" class="ma-0">
        Comparison
      </v-btn>
      <v-btn tile x-large dark text to="/filtering" class="ma-0">
        Filtering
      </v-btn>
    </div> -->
    <v-spacer></v-spacer>
    <div class="user-menu-section">
      <div v-if="userPrefsLoading">
        <v-progress-circular
          :size="16"
          :width="2"
          indeterminate
          color="white"
          class="mr-6"
        ></v-progress-circular>
      </div>
      <div v-else-if="userPrefsLoaded && developerAccess" class="d-flex align-center">
        <v-btn
          outlined
          dark
          :to="'/console'"
        >
          Go to console
        </v-btn>
        <v-toolbar-items class="ml-4">
          <v-list-item class="px-0">
            <UserMenu/>
          </v-list-item>
        </v-toolbar-items>
      </div>
      <div v-else-if="$route.name !== 'Login'" class="d-flex align-center">
        <v-btn
          outlined
          dark
          :to="'/register/beta'"
          class="mr-1"
          v-if="!inStaging"
        >
          Register
        </v-btn>
        <v-btn
          text
          dark
          :loading="!userStateKnown"
          :disabled="!userStateKnown"
          @click="signIn"
        >
          Sign In
        </v-btn>
      </div>
    </div>
  </v-app-bar>
  <!-- </div> -->
</template>

<script>

import AuthUtil from '../authUtil'

import { mapState } from 'vuex'

import UserMenu from './Navigation/UserMenu'

import Constants from '../constants.js'

export default {
  name: 'AppBar',
  components: {
    UserMenu
  },
  computed: {
    ...mapState({
      developerAccess: state => state.user.developerAccess,
      userPrefsLoaded: state => state.user.loaded,
      userPrefsLoading: state => state.user.loading
    }),
    userStateKnown () {
      let res = true
      if (AuthUtil.auth.currentUsercurrentUser) {
        res = this.userPrefsLoaded && !this.userPrefsLoading
      }
      return res
    },
    barColor () {
      let res = 'standar-bar'
      if (this.currentRouteRequiresDarkNav) {
        res = 'dark-bar'; 
      }
      return res
    },
    currentRouteRequiresDarkNav() {
      return this.$route.meta && this.$route.meta.requiresDarkNav;
    },
    inStaging () {
      return Constants.PIKLE_ENV && Constants.PIKLE_ENV === 'staging'
    },
  },
  methods:  {
    signIn() {
      window.location.assign(`${Constants.PIKLE_DOMAIN}/signin/ecommerce`)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.services-menu-section {
  .theme--dark.v-btn--active {
    border-bottom: 1px solid #FFF !important;
  }
  .theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before {
    opacity: 0 !important;
  }
}
</style>
<style scoped lang="scss">
.app-top-bar {
  // border-bottom: 1px solid #CCC;

  .developer-tag-line {
    height: 28px;
    display: flex;
    align-items: flex-end;
    color: #1e40aa;
    margin-left: 5px;
  }

  .back-button {
    margin-left: 92px;
    margin-right: 10px;
  }
}

.services-menu-section {
  margin-left: 50px;
  .v-btn--text {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.1rem;
  }
}

.user-menu-section {
  min-width: 200px;
  display: flex;
  justify-content: end;
  align-items: center;
}
</style>
