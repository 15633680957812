<template>
  <!-- <div class="app-top-bar"> -->
  <v-app-bar
    dense
    :absolute="!currentApp"
    class="dev-console-app-bar"
  >
    <div class="app-top-bar mx-0 d-flex align-center" :class="{'justify-center': currentApp}">
      <div v-if="currentApp" class="d-flex align-center current-app-title-section">

        <h3>{{ currentApp.name }}</h3>
      </div>
      <router-link v-else to="/console">
        <v-img
          alt="Pikle Logo"
          :src="require('../assets/logo-full-light.svg')"
          max-width="65"
        ></v-img>
      </router-link>
    </div>
    <!-- <v-spacer></v-spacer> -->
    <v-toolbar-items>
      <v-list-item class="px-0">
        <UserMenu/>
      </v-list-item>
    </v-toolbar-items>
  </v-app-bar>
  <!-- </div> -->
</template>

<script>
import { mapState } from 'vuex'

import UserMenu from './Navigation/UserMenu'

export default {
  name: 'AppBar',
  components: {
    UserMenu
  },
  computed: {
    ...mapState({
      currentApp: state => state.apps.currentApp
    })
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-top-bar {

  width: 100%;
  // border-bottom: 1px solid #CCC;

  .developer-tag-line {
    // height: 28px;
    // display: flex;
    // align-items: flex-end;
    color: #1e40aa;
    margin-left: 5px;
  }

  .current-app-title-section {
    // margin-left: 68px;
  }

  .back-button {
    // margin-left: 92px;
    // margin-right: 10px;
  }
}
</style>
