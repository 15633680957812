<template>
  <v-app id="pikle">
    <v-main>
      <v-container
        fluid
      >
        <v-row align="center" justify="center">
          <v-col
            cols="10"
            class="text-left"
          >
            <div class="privacy-policy">
              <h1>Privacy Policy</h1>
              <p>
                This privacy policy applies to all information collected or submitted on the Pikle ecommerce website.
              </p>
              <h2>Account information we collect</h2>
              <p>
                New accounts in Pikle are created with an email address and password and an email address and password is required to access the Pikle website.
              </p>
              <h2>General information we collect</h2>
              <p>
                We store information linked to your account that you enter into the Pikle ecommerce console. For example we store information about your product catalog including urls to products, values we find on each product page and product meta data like price and availability.
              </p>
              <h3>Technical basics</h3>
              <p>
                We use <a href="https://firebase.google.com/products/auth/">Firebase Authentication</a> to manage user accounts.
              </p>
              <p>
                Your email address and password are stored by Google Firebase.
              </p>
              <p>
                Our server software may also store basic technical information, such as your IP address, in temporary memory or logs.
              </p>
              <h4>Firebase</h4>
              <p>
                Firebase can use cookies on the site and similar tokens in the app to keep you logged in. It can also keep logged IP addresses for a few weeks. <a href="https://firebase.google.com/support/privacy">Firebase’s privacy policy is here</a>.
              </p>
              <p>
                Firebase Authentication uses an internally modified version of scrypt to hash account passwords
              </p>
              <!--Ads and analytics-->
              <h3>Analytics</h3>
              <p>
                Pikle collects aggregate, anonymous statistics, such as the percentage of users who use particular features, to improve the app.
              </p>
              <p>
                Pikle ecommerce collects account specificy data, such as the number of active product catalog items that use a certain feature so that accurate billing information can be generated for an account.
              </p>
              <!--No personal data is used to target Pikle’s ads. Each ad collects, and shares with its advertiser, only three numbers: total views, total taps, and total subscriptions from the ad.-->

              <h2>Information usage</h2>
              <p>
                We use the information we collect to operate and improve our website, apps, and customer support.
              </p>
              <p>
                We do not share personal information with outside parties except to the extent necessary to accomplish Pikle’s functionality. We may share anonymous, aggregate statistics with outside parties, such as how many people use Pikle and what sorts of options they are considering.
              </p>
              <p>
                We may disclose your information in response to subpoenas, court orders, or other legal requirements; to exercise our legal rights or defend against legal claims; to investigate, prevent, or take action regarding illegal activities, suspected fraud or abuse, violations of our policies; or to protect our rights and property.
              </p>
              <p>
                In the future, we may sell to, buy, merge with, or partner with other businesses. In such transactions, user information may be among the transferred assets.
              </p>
              <h2>Security</h2>
              <p>
                We implement a variety of security measures to help keep your information secure. For instance, all communication with the app and website requires HTTPS. Passwords are managed via Firebase and are hashed using industry-standard methods (Firebase scrypt).
              </p>
              <h2>Accessing, changing, or deleting information</h2>
              <p>
                You may access and change your information or delete your account from the Pikle.
              </p>
              <p>
                NOTE: During the Pikle ALPHA phase you may email <a href="mailto:admin@pikle.online">admin@pikle.online</a> to request your account be deleted.
              </p>
              <h4>Deleting your account</h4>
              <p>
                If you choose to delete your account information from the Pikle app, all your identifiable data will be deleted and you will no longer be able to log into the app.
              </p>
              <p>
                Deleted information may be kept in backups for up to 180 days.
              </p>
              <p>
                Pikle may delete your information at any time and for any reason, such as technical needs, legal concerns, abuse prevention, removal of idle accounts, data loss, or any other reason.
              </p>
              <h2>California Online Privacy Protection Act Compliance</h2>
              <p>
                We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.
              </p>
              <h2>Information for European Union Customers</h2>
              <p>
                By using Pikle and providing your information, you authorize us to collect, use, and store your information outside of the European Union.
              </p>
              <h2>International Transfers of Information</h2>
              <p>
                Information may be processed, stored, and used outside of the country in which you are located. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.
              </p>
              <h2>Your Consent</h2>
              <p>
                By using our site or apps, you consent to our privacy policy.
              </p>
              <h2>Contacting Us</h2>
              <p>
                If you have questions regarding this privacy policy, you may email <a href="mailto:admin@pikle.online">admin@pikle.online</a>.
              </p>
              <h2>Changes to this policy</h2>
              <p>
                If we decide to change our privacy policy, we will post those changes on this page.
              </p>
              <p>
                Summary of changes so far:
              </p>
              <ul>
                <li>March 31, 2023: First published.</li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PiklePrivacyStatement',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.privacy-policy {
  margin-top: 80px;
}
.comparison-list {
  display: flex;
  justify-content: space-around;
}
.logo-title {
  color: #1e40aa;
  text-transform: uppercase;
}
</style>
