import Vue from 'vue'
import optionsAPI from '../../api/options'
import * as types from '../types/training'
import * as commonTypes from '../types/common'

export const getDefaultState = () => ({
  isTraining: false,
  isCheckingContent: false,
  isImporting: false,
  trainedDecisionId: null,
  trainedOption: null
})

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_RUN_TEST] ({ commit }, payload) {
    commit(types.MUTATION_SET_TRAINING, true)
    optionsAPI.scrapeTaxonURLs(payload.appId, payload.appKey, payload.taxon, [payload.url], payload.criteria, (response) => {
      if (response && response.status === 'success') {
        const optionId = response.optionIds ? response.optionIds[0] : null
        const newOptionData = {id: optionId, training: true}
        // console.log(`Got a response from scrapeTaxon ${JSON.stringify(response)}`)
        commit(types.MUTATION_SET_TRAINED_DECISION_ID, response.decisionId)
        commit(types.MUTATION_SET_TRAINED_OPTION, newOptionData)
      }
    })
  },
  [types.ACTION_BULK_IMPORT] ({ commit }, payload) {
    // console.log(`About to bulk import ${JSON.stringify(payload)}`)
    commit(types.MUTATION_SET_IMPORTING, true)
    optionsAPI.scrapeTaxonURLs(payload.appId, payload.appKey, payload.taxon, payload.urls, payload.criteria, (response) => {
      if (response && response.status === 'success') {
        commit(types.MUTATION_SET_IMPORTING, false)
      }
    })
  },
  [types.ACTION_BULK_CONTENT_CHECK] ({ commit }, payload) {
    // console.log(`About to bulk content check ${JSON.stringify(payload)}`)
    commit(types.MUTATION_SET_CHECKING_CONTENT, true)
    optionsAPI.contentCheckTaxonURLs(payload.appId, payload.appKey, payload.taxon, payload.urls, (response) => {
      if (response && response.status === 'success') {
        commit(types.MUTATION_SET_CHECKING_CONTENT, false)
        // console.log(`Finished bulk content check res = ${JSON.stringify(response)}`)
      }
    })
  },
  [types.ACTION_BULK_ALL_VALUES_CHECK] ({ commit }, payload) {
    // console.log(`About to bulk content check ${JSON.stringify(payload)}`)
    commit(types.MUTATION_SET_CHECKING_CONTENT, true)
    optionsAPI.checkAllValuesForTaxonURLs(payload.appId, payload.appKey, payload.taxon, payload.urls, (response) => {
      if (response && response.status === 'success') {
        commit(types.MUTATION_SET_CHECKING_CONTENT, false)
        // console.log(`Finished bulk content check res = ${JSON.stringify(response)}`)
      }
    })
  },
  // [types.ACTION_CHECK_OPTION_SCRAPE_STATE] ({ commit }, payload) {
  //   commit(types.MUTATION_SET_TRAINING, true)
  //   optionsAPI.getOptionState(payload.optionId, response => {
  //     // TODO
  //     // console.log(`Got an option state response ${response}`)
  //   })
  // }
}

// mutations
const mutations = {
  [commonTypes.MUTATION_RESET_STATE] (state) {
    // console.log('RESETING STATE in Decisions Module')
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_SET_TRAINED_OPTION] (state, newOptionData) {
    state.trainedOption = newOptionData
    // if (newOptionData && state.trainedOption && state.trainedOption.id === newOptionData.id) {
    //   // Set the option state to training
    //   Vue.set(state.trainedOption, 'training', newOptionData.training)
    // } else {
    //   state.trainedOption = newOptionData
    // }
  },
  [types.MUTATION_SET_TRAINED_DECISION_ID] (state, decisionId) {
    state.trainedDecisionId = decisionId
  },
  [types.MUTATION_SET_TRAINING] (state, newVal) {
    state.isTraining = newVal
  },
  [types.MUTATION_SET_CHECKING_CONTENT] (state, newVal) {
    state.isCheckingContent = newVal
  },
  [types.MUTATION_SET_IMPORTING] (state, newVal) {
    state.isImporting = newVal
  },
  [types.MUTATION_SET_OPTION_IS_TRAINING] (state, newVal) {
    if (state.trainedOption) {
      state.trainedOption.training = newVal
    }
  },
  [types.MUTATION_SET_OPTION_IS_SCRAPING_SINGLE_VALUE] (state, newVal) {
    if (state.trainedOption) {
      Vue.set(state.trainedOption, 'scrapingSynonym', newVal)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
